import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_SERVICE_PROVIDER } from "Redux/services/apiTypes";

// ----- Get Supplier Watcher -----
export function* watchGetSupplierSaga() {
  yield takeLatest(actionTypes.GET_SUPPLIER_METHOD, getSupplier);
}

// ----- Saga -----
function* getSupplier(action) {
  try {
    let url = {
      type: `${API_SERVICE_PROVIDER}${action.payload.query}`,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SUPPLIER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SUPPLIER_FAIL, payload: error });
  }
}

// ----- Get Single Supplier Watcher -----
export function* watchGetSingleSupplierSaga() {
  yield takeLatest(actionTypes.GET_SINGLE_SUPPLIER_METHOD, getSingleSupplier);
}

// ----- Saga -----
function* getSingleSupplier(action) {
  try {
    let url = {
      type: `${API_SERVICE_PROVIDER}${action.payload.query}`,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SINGLE_SUPPLIER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SINGLE_SUPPLIER_FAIL, payload: error });
  }
}
