import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_SERVICE_PROVIDER } from "Redux/services/apiTypes";

// ----- Get Notification Watcher -----
export function* watchGetStaffSaga() {
  yield takeLatest(actionTypes.GET_STAFF_LIST_METHOD, getStaffListSaga);
}

// ----- Saga -----
function* getStaffListSaga(action) {
  try {
    let url = {
      type: API_SERVICE_PROVIDER + action.payload.query,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_STAFF_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_STAFF_LIST_FAIL, payload: error });
  }
}
