import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_SERVICE_REQUEST } from "Redux/services/apiTypes";

// ----- Get Server Request Watcher -----
export function* watchGetServiceRequestSaga() {
  yield takeLatest(
    actionTypes.GET_SERVICE_REQUEST_METHOD,
    getServiceRequestSaga
  );
}
export function* watchGetSingleServiceRequestSaga() {
  yield takeLatest(
    actionTypes.GET_SINGLE_SERVICE_REQUEST_METHOD,
    getSingleServiceRequestSaga
  );
}

// ----- Saga -----
function* getServiceRequestSaga(action) {
  try {
    let url = {
      type: API_SERVICE_REQUEST + action.payload.query,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SERVICE_REQUEST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SERVICE_REQUEST_FAIL, payload: error });
  }
}
function* getSingleServiceRequestSaga(action) {
  try {
    let url = {
      type: `${API_SERVICE_REQUEST}${action.payload.id}/`,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({
      type: actionTypes.GET_SINGLE_SERVICE_REQUEST_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_SERVICE_REQUEST_FAIL,
      payload: error,
    });
  }
}
