import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_SERVICES, API_SESSION } from "Redux/services/apiTypes";

// ----- Get Session Watcher -----
export function* watchGetSessionSaga() {
  yield takeLatest(actionTypes.GET_SESSION_METHOD, getSessionSaga);
}

export function* watchGetSlotSaga() {
  yield takeLatest(actionTypes.GET_SLOT_METHOD, getSloteSaga);
}

// ----- Saga -----
function* getSessionSaga(action) {
  try {
    let url = {
      type:
        `${API_SERVICES}${action.payload.id}/sessions/` + action.payload.query,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SESSION_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SESSION_FAIL, payload: error });
  }
}

function* getSloteSaga(action) {
  try {
    let url = {
      type: `${API_SESSION}${action.payload.id}/slots/`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SLOT_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SLOT_FAIL, payload: error });
  }
}
