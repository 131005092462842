import { all } from "redux-saga/effects";
import { watchGetSingleUserSaga, watchGetUserSaga } from "./userSaga";
import { watchGetServiceSaga, watchGetSingleServiceSaga } from "./serviceSaga";
import {
  watchGetServiceRequestSaga,
  watchGetSingleServiceRequestSaga,
} from "./serviceRequestSaga";
import { watchGetBannerSaga, watchGetSingleBannerSaga } from "./bannerSaga";
import {
  watchGetSuggestionSaga,
  watchGetSingleSuggestionSaga,
} from "./suggestionSaga";
import { watchGetNotificationSaga } from "./notificationSaga";
import {
  watchGetPromoCodeSaga,
  watchGetSinglePromoCodeSaga,
} from "./promoCodeSaga";
import { watchGetFaqSaga, watchGetSingleFaqSaga } from "./faqSaga";
import {
  watchGetCategorySaga,
  watchGetSubCategorySaga,
  watchGetThirdCategorySaga,
} from "./categorySaga";
import { watchGetSessionSaga, watchGetSlotSaga } from "./sessionSaga";
import {
  watchGetSingleSupplierSaga,
  watchGetSupplierSaga,
} from "./supplierSaga";
import { watchGetDashboardDataWatcher } from "./dashboardSaga";
import { getWithdrawalListWatcher } from "./withdrawalListSaga";
import { getRefundListWatcher } from "./refundListSaga";
import { getServiceRatingListWatcher } from "./serviceRatingSaga";
import { getPaybackListWatcher } from "./paybackListSaga";
import { watchGetPromoCodeHistorySaga } from "./promoCodeHistorySaga";
import { watchApiLogsSaga } from "./apiLogsSaga";
import { watchSingleApiLogsSaga } from "./singleApiLogsSaga";
import { watchGetStaffSaga } from "./staffSaga";
import { watchServiceReviewsSaga } from "./serviceReviewsSaga";
import { watchSingleServiceReviewsSaga } from "./singleServiceReviewsSaga";
import { watchGetUserReviewsSaga } from "./userReviewsSaga";
import {
  watchGetBannerTitleSaga,
  watchGetSingleBannerTitleSaga,
} from "./bannerTitleSaga";

function* rootSaga() {
  yield all([
    watchGetUserSaga(),
    watchGetServiceSaga(),
    watchGetServiceRequestSaga(),
    watchGetBannerSaga(),
    watchGetSuggestionSaga(),
    watchGetNotificationSaga(),
    watchGetPromoCodeSaga(),
    watchGetFaqSaga(),
    watchGetCategorySaga(),
    watchGetSubCategorySaga(),
    watchGetSingleBannerSaga(),
    watchGetSingleSuggestionSaga(),
    watchGetSingleFaqSaga(),
    watchGetSinglePromoCodeSaga(),
    watchGetSingleServiceSaga(),
    watchGetSessionSaga(),
    watchGetSlotSaga(),
    watchGetSingleServiceRequestSaga(),
    watchGetSupplierSaga(),
    watchGetSingleSupplierSaga(),
    watchGetDashboardDataWatcher(),
    getWithdrawalListWatcher(),
    getRefundListWatcher(),
    getServiceRatingListWatcher(),
    watchGetSingleUserSaga(),
    getPaybackListWatcher(),
    watchGetThirdCategorySaga(),
    watchGetPromoCodeHistorySaga(),
    watchApiLogsSaga(),
    watchSingleApiLogsSaga(),
    watchGetStaffSaga(),
    watchServiceReviewsSaga(),
    watchSingleServiceReviewsSaga(),
    watchGetUserReviewsSaga(),
    watchGetBannerTitleSaga(),
    watchGetSingleBannerTitleSaga(),
  ]);
}

export default rootSaga;
