import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  refundListLoading: false,
  refundListError: "",
  refundListRes: "",
};

export default function refundListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_REFUND_LIST_METHOD:
      return {
        ...state,
        refundListLoading: true,
        refundListError: "",
      };

    case actionTypes.GET_REFUND_LIST_SUCCESS:
      return {
        ...state,
        refundListLoading: false,
        refundListRes: action.payload,
        refundListError: "",
      };

    case actionTypes.GET_REFUND_LIST_FAIL:
      return {
        ...state,
        refundListLoading: false,
        refundListError: action.payload,
      };

    case actionTypes.GET_REFUND_LIST_CLEAR:
      return {
        ...state,
        refundListLoading: false,
        refundListError: action.payload,
        refundListRes: "",
      };
    default:
      return state;
  }
}
