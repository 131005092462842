import { actionTypes } from "../services/actionTypes";

export default function authReducer(
  state = {
    isUserLoggedIn: undefined,
    userRole: undefined,
  },
  action
) {
  switch (action.type) {
    case actionTypes.LOGIN_USER_METHOD:
      return {
        ...state,
        isUserLoggedIn: true,
        userRole: action.payload,
      };
      break;
    case actionTypes.LOGOUT_USER_METHOD:
      return { ...state, isUserLoggedIn: false, userRole: undefined };
      break;

    /////////////////////////////////////////////////////////////////////

    default:
      return state;
  }
}
