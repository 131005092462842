import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_USERS, API_USERS_CUSTOMER } from "Redux/services/apiTypes";

// ----- Get User Watcher -----
export function* watchGetUserSaga() {
  yield takeLatest(actionTypes.GET_USERS_METHOD, getUserSaga);
}

// ----- Saga -----
function* getUserSaga(action) {
  try {
    let url = {
      type: `${API_USERS_CUSTOMER}${action.payload.query}`,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_USERS_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_USERS_FAIL, payload: error });
  }
}

// ----- Get Single User Watcher -----
export function* watchGetSingleUserSaga() {
  yield takeLatest(actionTypes.GET_SINGLE_USER_METHOD, getSingleUser);
}

// ----- Saga -----
function* getSingleUser(action) {
  try {
    let url = {
      type: `${API_USERS}${action.payload.query}`,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SINGLE_USER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SINGLE_USER_FAIL, payload: error });
  }
}
