import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, Typography } from "@mui/material";
import apiCall from "Redux/services/apiCall";
import { API_USERS_LOGIN } from "Redux/services/apiTypes";
import CButton from "common/Button";
import Input from "common/CInput";
import Toaster from "common/Toaster";
import { useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getErrorMessage, loginUser } from "utils";
import "./style.css";

const Login = () => {
  const navigate = useNavigate();

  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = () => {
    setPasswordShow(!passwordShow);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const {} = useSelector(
    (state) => ({
      res: state.authReducer,
    }),
    shallowEqual
  );

  const handleSubmit = () => {
    if (phone === "") {
      Toaster({ type: "error", message: "Please Fill Phone Number" });
      return;
    } else if (password === "") {
      Toaster({ type: "error", message: "Please Fill Password" });
      return;
    }
    setLoading(true);
    let payload = {
      phone: phone,
      password: password,
    };
    let apiData = {
      type: API_USERS_LOGIN,
      payload: payload,
      apiType: "POST",
    };
    apiCall(apiData)
      .then((res) => {
        navigate("/admin/dashboard", { replace: true });
        Toaster({ type: "success", message: "Login Successfully" });
        loginUser(res);
      })
      .catch((error) => {
        Toaster({ type: "error", message: getErrorMessage(error) });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const submit_data = (e) => {
    if (e.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="loginContainer">
        <div className="loginLeft">
          <Typography variant="h4" sx={{ fontWeight: "bold" }}>
            Sign In
          </Typography>
          <Typography>Enter your details below</Typography>
          <Input
            label="Phone"
            value={phone}
            onKeyDown={submit_data}
            onChange={(e) => {
              if (e.target.value.length === 10) return;
              setPhone(e.target.value);
            }}
          />
          <Input
            type={passwordShow ? "text" : "password"}
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={submit_data}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {!passwordShow ? (
                    <VisibilityOff color="black" />
                  ) : (
                    <Visibility color="black" />
                  )}
                </IconButton>
              </InputAdornment>
            }
          />
          <CButton
            isLoading={loading}
            title="Login"
            style={{ width: "100%" }}
            onClick={handleSubmit}
          />
        </div>

        <div className="loginRight">
          <img src="/login.png" alt="loginImage" className="loginImage" />
        </div>
      </div>
    </>
  );
};

export default Login;
