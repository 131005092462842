//BASE URLS
export const API_V1 = process.env.REACT_APP_BASE_URL + "/api/v1/";

//END POINTS
export const API_USERS = API_V1 + "users/";
export const API_SERVICES = API_V1 + "service/";
export const API_SERVICE_REQUEST = API_V1 + "service-request/";
export const API_SERVICE_PROVIDER = API_V1 + "service-provider/";
export const API_SERVICE_PROVIDERS = API_V1 + "service-providers/";
export const API_BANNER = API_V1 + "banner/";
export const API_SUGGESTION = API_V1 + "suggestion/";
export const API_NOTIFICATION = API_V1 + "notification/";
export const API_PROMO_CODE = API_V1 + "promo-code/";
export const API_FAQ = API_V1 + "faq/";
export const API_CATEGORY = API_V1 + "category/";
export const API_SESSION = API_V1 + "session/";

//APIS URLS
export const API_USERS_LOGIN = API_USERS + "sign-in/";
export const API_NOTIFICATION_TYPE = API_NOTIFICATION + "notification-type/";
export const API_AUDIENCE_TYPE = API_NOTIFICATION + "audience-type/";
export const API_BECOME_PROVIDER = API_SERVICE_PROVIDER + "became-provider/";
export const API_DASHBOARD = API_USERS + "dashboard/";
export const API_WITHDRAWAL_REQUEST =
  API_SERVICE_PROVIDERS + "withdraw-request/";
export const API_REFUNDS = API_V1 + "refunds";
export const API_USERS_CUSTOMER = API_USERS + "customer/";
export const PAYBACK_LIST = API_SERVICE_PROVIDERS + "payback-request/";
export const API_LOGS = API_V1 + "logs/";
export const SERVICE_REVIEWS = API_V1 + "service-rating/";
export const BANNER_TITLE = API_V1 + "banner-title/";
