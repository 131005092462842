import { initialTableData } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  promoCodeLoading: false,
  isPromoCodeResSuccess: false,
  promoCodeError: "",
  promoCodeRes: "",

  singlePromoCodeLoading: false,
  singlePromoCodeError: "",
  singlePromoCodeRes: "",
  isSinglePromoCodeResSuccess: false,
};

export default function promoCodeReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_PROMO_CODE_METHOD:
      return {
        ...state,
        promoCodeLoading: true,
        promoCodeError: "",
        isPromoCodeResSuccess: false,
      };

    case actionTypes.GET_PROMO_CODE_SUCCESS:
      return {
        ...state,
        promoCodeLoading: false,
        promoCodeRes: action.payload,
        promoCodeError: "",
        isPromoCodeResSuccess: true,
      };

    case actionTypes.GET_PROMO_CODE_FAIL:
      return {
        ...state,
        promoCodeLoading: false,
        promoCodeError: action.payload,
        isPromoCodeResSuccess: false,
      };

    case actionTypes.GET_PROMO_CODE_CLEAR:
      return {
        ...state,
        promoCodeLoading: false,
        promoCodeError: "",
        promoCodeRes: "",
        isPromoCodeResSuccess: false,
      };

    /////////////////////////////////////////////////////

    case actionTypes.GET_SINGLE_PROMO_CODE_METHOD:
      return {
        ...state,
        singlePromoCodeLoading: true,
        singlePromoCodeError: "",
        isSinglePromoCodeResSuccess: false,
      };

    case actionTypes.GET_SINGLE_PROMO_CODE_SUCCESS:
      return {
        ...state,
        singlePromoCodeLoading: false,
        singlePromoCodeRes: action.payload,
        singlePromoCodeError: "",
        isSinglePromoCodeResSuccess: true,
      };

    case actionTypes.GET_SINGLE_PROMO_CODE_FAIL:
      return {
        ...state,
        singlePromoCodeLoading: false,
        singlePromoCodeError: action.payload,
        isSinglePromoCodeResSuccess: false,
      };

    case actionTypes.GET_SINGLE_PROMO_CODE_CLEAR:
      return {
        ...state,
        isSinglePromoCodeResSuccess: false,
        singlePromoCodeError: "",
        singlePromoCodeRes: "",
      };

    default:
      return state;
  }
}
