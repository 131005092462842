import { PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  notificationLoading: false,
  notificationError: "",
  notificationRes: PAGINATION_INITIAL_LIST_RES,
  isNotificationResSuccess: false,
};

export default function notificationReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_NOTIFICATION_METHOD:
      return {
        ...state,
        notificationLoading: true,
        notificationError: "",
        isNotificationResSuccess: false,
      };

    case actionTypes.GET_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notificationLoading: false,
        notificationRes: action.payload,
        notificationError: "",
        isNotificationResSuccess: true,
      };

    case actionTypes.GET_NOTIFICATION_FAIL:
      return {
        ...state,
        notificationLoading: false,
        notificationError: action.payload,
        isNotificationResSuccess: false,
      };

    case actionTypes.GET_NOTIFICATION_CLEAR:
      return {
        ...state,
        notificationLoading: false,
        notificationError: "",
        notificationRes: PAGINATION_INITIAL_LIST_RES,
        isNotificationResSuccess: false,
      };

    default:
      return state;
  }
}
