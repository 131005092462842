import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_NOTIFICATION } from "Redux/services/apiTypes";

// ----- Get Notification Watcher -----
export function* watchGetNotificationSaga() {
  yield takeLatest(actionTypes.GET_NOTIFICATION_METHOD, getNotificationSaga);
}

// ----- Saga -----
function* getNotificationSaga(action) {
  try {
    let url = {
      type: API_NOTIFICATION + action.payload.query,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_NOTIFICATION_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_NOTIFICATION_FAIL, payload: error });
  }
}
