import { useState } from "react";

// mui
import { Box } from "@mui/material";

// navigation
import { Outlet } from "react-router-dom";

// components
import SideBar from "./SideBar";

function Layout() {
  const [open, setOpen] = useState(true);

  const handleDrawer = () => {
    setOpen(!open);
  };

  return (
    <Box sx={{ display: "flex" }}>
      {/* <CssBaseline /> */}
      {/* <Topbar open={open} /> */}
      <SideBar open={open} handleDrawer={handleDrawer} />

      <Box sx={{ width: "100%", overflow: "hidden" }}>
        <Box
          sx={{
            width: "100%",
            overflow: "auto",
          }}
        >
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
export default Layout;
