import { PAGINATION_INITIAL_LIST_RES, initialTableData } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  bannerTitleLoading: false,
  isBannerTitleResSuccess: false,
  bannerTitleError: "",
  bannerTitleRes: PAGINATION_INITIAL_LIST_RES,

  singleBannerTitleLoading: false,
  isSingleBannerTitleResSuccess: false,
  singleBannerTitleError: "",
  singleBannerTitleRes: "",
};

export default function bannerTitleReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_BANNER_TITLE_METHOD:
      return {
        ...state,
        bannerTitleLoading: true,
        bannerTitleError: "",
        isBannerTitleResSuccess: true,
      };

    case actionTypes.GET_BANNER_TITLE_SUCCESS:
      return {
        ...state,
        bannerTitleLoading: false,
        bannerTitleRes: action.payload,
        bannerTitleError: "",
        isBannerTitleResSuccess: false,
      };

    case actionTypes.GET_BANNER_TITLE_FAIL:
      return {
        ...state,
        bannerTitleLoading: false,
        bannerTitleError: action.payload,
        isBannerTitleResSuccess: false,
      };

    case actionTypes.GET_BANNER_TITLE_CLEAR:
      return {
        ...state,
        bannerTitleLoading: false,
        bannerTitleError: "",
        bannerTitleRes: initialTableData,
        isBannerTitleResSuccess: false,
      };

    ///////////////////////////////////////////////////////

    case actionTypes.GET_SINGLE_BANNER_TITLE_METHOD:
      return {
        ...state,
        singleBannerTitleLoading: true,
        singleBannerTitleError: "",
        isSingleBannerTitleResSuccess: false,
      };

    case actionTypes.GET_SINGLE_BANNER_TITLE_SUCCESS:
      return {
        ...state,
        singleBannerTitleLoading: false,
        singleBannerTitleRes: action.payload,
        singleBannerTitleError: "",
        isSingleBannerTitleResSuccess: true,
      };

    case actionTypes.GET_SINGLE_BANNER_TITLE_FAIL:
      return {
        ...state,
        singleBannerTitleLoading: false,
        singleBannerTitleError: action.payload,
        isSingleBannerTitleResSuccess: false,
      };

    case actionTypes.GET_SINGLE_BANNER_TITLE_CLEAR:
      return {
        ...state,
        singleBannerTitleLoading: false,
        singleBannerTitleError: "",
        singleBannerTitleRes: "",
        isSingleBannerTitleResSuccess: false,
      };

    default:
      return state;
  }
}
