import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = ({ role }) => {
  const { res } = useSelector(
    (state) => ({
      res: state.authReducer,
    }),
    shallowEqual
  );

  return res.isUserLoggedIn && role.includes(res.userRole) ? (
    <Outlet />
  ) : (
    <Navigate to="/admin/login" />
  );
};

export default ProtectedRoutes;
