import { initialTableData, PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  serviceRequestLoading: false,
  isServiceRequestResSuccess: false,
  serviceRequestError: "",
  serviceRequestRes: PAGINATION_INITIAL_LIST_RES,

  singleServiceRequestLoading: false,
  isSingleServiceRequestResSuccess: false,
  singleServiceRequestError: "",
  singleServiceRequestRes: "",
};

export default function serviceRequestReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_SERVICE_REQUEST_METHOD:
      return {
        ...state,
        serviceRequestLoading: true,
        serviceRequestError: "",
        isServiceRequestResSuccess: false,
      };

    case actionTypes.GET_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        serviceRequestLoading: false,
        serviceRequestRes: action.payload,
        serviceRequestError: "",
        isServiceRequestResSuccess: true,
      };

    case actionTypes.GET_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        serviceRequestLoading: false,
        serviceRequestError: action.payload,
        isServiceRequestResSuccess: false,
      };

    case actionTypes.GET_SERVICE_REQUEST_CLEAR:
      return {
        ...state,
        serviceRequestLoading: false,
        serviceRequestError: "",
        serviceRequestRes: PAGINATION_INITIAL_LIST_RES,
        isServiceRequestResSuccess: false,
      };

    //////////////////////////////////////////////////

    case actionTypes.GET_SINGLE_SERVICE_REQUEST_METHOD:
      return {
        ...state,
        singleServiceRequestLoading: true,
        singleServiceRequestError: "",
        isSingleServiceRequestResSuccess: false,
      };

    case actionTypes.GET_SINGLE_SERVICE_REQUEST_SUCCESS:
      return {
        ...state,
        singleServiceRequestLoading: false,
        singleServiceRequestRes: action.payload,
        singleServiceRequestError: "",
        isSingleServiceRequestResSuccess: true,
      };

    case actionTypes.GET_SINGLE_SERVICE_REQUEST_FAIL:
      return {
        ...state,
        singleServiceRequestLoading: false,
        singleServiceRequestError: action.payload,
        isSingleServiceRequestResSuccess: false,
      };

    case actionTypes.GET_SINGLE_SERVICE_REQUEST_CLEAR:
      return {
        ...state,
        singleServiceRequestLoading: false,
        singleServiceRequestError: "",
        singleServiceRequestRes: "",
        isSingleServiceRequestResSuccess: false,
      };

    default:
      return state;
  }
}
