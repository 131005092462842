export const actionTypes = {
  //---- Auth ----//
  LOGIN_USER_METHOD: "login_user_method",
  LOGOUT_USER_METHOD: "logout_user_method",
  SAVE_USER_DATA: "save_user_data",

  //----Get Users ----//
  GET_USERS_METHOD: "GET_USERS_METHOD",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAIL: "GET_USERS_FAIL",
  GET_USERS_CLEAR: "GET_USERS_CLEAR",

  //----Get Services ----//
  GET_SERVICES_METHOD: "GET_SERVICES_METHOD",
  GET_SERVICES_SUCCESS: "GET_SERVICES_SUCCESS",
  GET_SERVICES_FAIL: "GET_SERVICES_FAIL",
  GET_SERVICES_CLEAR: "GET_SERVICES_CLEAR",

  // ---- Get Single Category ----//
  GET_SINGLE_SERVICE_METHOD: "GET_SINGLE_SERVICE_METHOD",
  GET_SINGLE_SERVICE_SUCCESS: "GET_SINGLE_SERVICE_SUCCESS",
  GET_SINGLE_SERVICE_FAIL: "GET_SINGLE_SERVICE_FAIL",
  GET_SINGLE_SERVICE_CLEAR: "GET_SINGLE_SERVICE_CLEAR",

  //----Get Services Request----//
  GET_SERVICE_REQUEST_METHOD: "GET_SERVICES_REQUEST_METHOD",
  GET_SERVICE_REQUEST_SUCCESS: "GET_SERVICES_REQUEST_SUCCESS",
  GET_SERVICE_REQUEST_FAIL: "GET_SERVICES_REQUEST_FAIL",
  GET_SERVICE_REQUEST_CLEAR: "GET_SERVICES_REQUEST_CLEAR",

  //----Get Banner ----//
  GET_BANNER_METHOD: "GET_BANNER_METHOD",
  GET_BANNER_SUCCESS: "GET_BANNER_SUCCESS",
  GET_BANNER_FAIL: "GET_BANNER_FAIL",
  GET_BANNER_CLEAR: "GET_BANNER_CLEAR",

  //----Get Single Banner ----//
  GET_SINGLE_BANNER_METHOD: "GET_SINGLE_BANNER_METHOD",
  GET_SINGLE_BANNER_SUCCESS: "GET_SINGLE_BANNER_SUCCESS",
  GET_SINGLE_BANNER_FAIL: "GET_SINGLE_BANNER_FAIL",
  GET_SINGLE_BANNER_CLEAR: "GET_SINGLE_BANNER_CLEAR",

  //----Get Suggestion ----//
  GET_SUGGESTION_METHOD: "GET_SUGGESTION_METHOD",
  GET_SUGGESTION_SUCCESS: "GET_SUGGESTION_SUCCESS",
  GET_SUGGESTION_FAIL: "GET_SUGGESTION_FAIL",
  GET_SUGGESTION_CLEAR: "GET_SUGGESTION_CLEAR",

  //----Get Single Suggestion ----//
  GET_SINGLE_SUGGESTION_METHOD: "GET_SINGLE_SUGGESTION_METHOD",
  GET_SINGLE_SUGGESTION_SUCCESS: "GET_SINGLE_SUGGESTION_SUCCESS",
  GET_SINGLE_SUGGESTION_FAIL: "GET_SINGLE_SUGGESTION_FAIL",
  GET_SINGLE_SUGGESTION_CLEAR: "GET_SINGLE_SUGGESTION_CLEAR",

  //----Get Notification ----//
  GET_NOTIFICATION_METHOD: "GET_NOTIFICATION_METHOD",
  GET_NOTIFICATION_SUCCESS: "GET_NOTIFICATION_SUCCESS",
  GET_NOTIFICATION_FAIL: "GET_NOTIFICATION_FAIL",
  GET_NOTIFICATION_CLEAR: "GET_NOTIFICATION_CLEAR",

  //----Get Promo Code ----//
  GET_PROMO_CODE_METHOD: "GET_PROMO_CODE_METHOD",
  GET_PROMO_CODE_SUCCESS: "GET_PROMO_CODE_SUCCESS",
  GET_PROMO_CODE_FAIL: "GET_PROMO_CODE_FAIL",
  GET_PROMO_CODE_CLEAR: "GET_PROMO_CODE_CLEAR",

  //----Get Single Promo Code ----//
  GET_SINGLE_PROMO_CODE_METHOD: "GET_SINGLE_PROMO_CODE_METHOD",
  GET_SINGLE_PROMO_CODE_SUCCESS: "GET_SINGLE_PROMO_CODE_SUCCESS",
  GET_SINGLE_PROMO_CODE_FAIL: "GET_SINGLE_PROMO_CODE_FAIL",
  GET_SINGLE_PROMO_CODE_CLEAR: "GET_SINGLE_PROMO_CODE_CLEAR",

  //----Get Faq ----//
  GET_FAQ_METHOD: "GET_FAQ_METHOD",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  GET_FAQ_FAIL: "GET_FAQ_FAIL",
  GET_FAQ_CLEAR: "GET_FAQ_CLEAR",

  //----Get Single Faq ----//
  GET_SINGLE_FAQ_METHOD: "GET_SINGLE_FAQ_METHOD",
  GET_SINGLE_FAQ_SUCCESS: "GET_SINGLE_FAQ_SUCCESS",
  GET_SINGLE_FAQ_FAIL: "GET_SINGLE_FAQ_FAIL",
  GET_SINGLE_FAQ_CLEAR: "GET_SINGLE_FAQ_CLEAR",

  //----Get Category ----//
  GET_CATEGORY_METHOD: "GET_CATEGORY_METHOD",
  GET_CATEGORY_SUCCESS: "GET_CATEGORY_SUCCESS",
  GET_CATEGORY_FAIL: "GET_CATEGORY_FAIL",
  GET_CATEGORY_CLEAR: "GET_CATEGORY_CLEAR",

  // ---- Get Sub Category ----//
  GET_SUB_CATEGORY_METHOD: "GET_SUB_CATEGORY_METHOD",
  GET_SUB_CATEGORY_SUCCESS: "GET_SUB_CATEGORY_SUCCESS",
  GET_SUB_CATEGORY_FAIL: "GET_SUB_CATEGORY_FAIL",
  GET_SUB_CATEGORY_CLEAR: "GET_SUB_CATEGORY_CLEAR",

  // ---- Get Third Category ----//
  GET_THIRD_CATEGORY_METHOD: "GET_THIRD_CATEGORY_METHOD",
  GET_THIRD_CATEGORY_SUCCESS: "GET_THIRD_CATEGORY_SUCCESS",
  GET_THIRD_CATEGORY_FAIL: "GET_THIRD_CATEGORY_FAIL",
  GET_THIRD_CATEGORY_CLEAR: "GET_THIRD_CATEGORY_CLEAR",

  // ---- Get Sessions ----//
  GET_SESSION_METHOD: "GET_SESSION_METHOD",
  GET_SESSION_SUCCESS: "GET_SESSION_SUCCESS",
  GET_SESSION_FAIL: "GET_SESSION_FAIL",
  GET_SESSION_CLEAR: "GET_SESSION_CLEAR",

  // ---- Get Slot ----//
  GET_SLOT_METHOD: "GET_SLOT_METHOD",
  GET_SLOT_SUCCESS: "GET_SLOT_SUCCESS",
  GET_SLOT_FAIL: "GET_SLOT_FAIL",
  GET_SLOT_CLEAR: "GET_SLOT_CLEAR",

  // ---- Get Single Service Request ----//
  GET_SINGLE_SERVICE_REQUEST_METHOD: "GET_SINGLE_SERVICE_REQUEST_METHOD",
  GET_SINGLE_SERVICE_REQUEST_SUCCESS: "GET_SINGLE_SERVICE_REQUEST_SUCCESS",
  GET_SINGLE_SERVICE_REQUEST_FAIL: "GET_SINGLE_SERVICE_REQUEST_FAIL",
  GET_SINGLE_SERVICE_REQUEST_CLEAR: "GET_SINGLE_SERVICE_REQUEST_CLEAR",

  // ---- Get Suppliers ----//
  GET_SUPPLIER_METHOD: "GET_SUPPLIER_METHOD",
  GET_SUPPLIER_SUCCESS: "GET_SUPPLIER_SUCCESS",
  GET_SUPPLIER_FAIL: "GET_SUPPLIER_FAIL",
  GET_SUPPLIER_CLEAR: "GET_SUPPLIER_CLEAR",

  GET_SINGLE_SUPPLIER_METHOD: "GET_SINGLE_SUPPLIER_METHOD",
  GET_SINGLE_SUPPLIER_SUCCESS: "GET_SINGLE_SUPPLIER_SUCCESS",
  GET_SINGLE_SUPPLIER_FAIL: "GET_SINGLE_SUPPLIER_FAIL",
  GET_SINGLE_SUPPLIER_CLEAR: "GET_SINGLE_SUPPLIER_CLEAR",

  //---- Clear error ----//
  CLEAR_ERROR: "clear_error",

  // ---- Dashboard Actions ----//
  GET_DASHBOARD_DATA_METHOD: "GET_DASHBOARD_DATA_METHOD",
  GET_DASHBOARD_DATA_SUCCESS: "GET_DASHBOARD_DATA_SUCCESS",
  GET_DASHBOARD_DATA_FAIL: "GET_DASHBOARD_DATA_FAIL",
  GET_DASHBOARD_DATA_CLEAR: "GET_DASHBOARD_DATA_CLEAR",

  // ---- Money Actions ----//
  GET_WITHDRAWAL_LIST_METHOD: "GET_WITHDRAWAL_LIST_METHOD",
  GET_WITHDRAWAL_LIST_SUCCESS: "GET_WITHDRAWAL_LIST_SUCCESS",
  GET_WITHDRAWAL_LIST_FAIL: "GET_WITHDRAWAL_LIST_FAIL",
  GET_WITHDRAWAL_LIST_CLEAR: "GET_WITHDRAWAL_LIST_CLEAR",
  // ---- Refund Actions ----//
  GET_REFUND_LIST_METHOD: "GET_REFUND_LIST_METHOD",
  GET_REFUND_LIST_SUCCESS: "GET_REFUND_LIST_SUCCESS",
  GET_REFUND_LIST_FAIL: "GET_REFUND_LIST_FAIL",
  GET_REFUND_LIST_CLEAR: "GET_REFUND_LIST_CLEAR",

  // ---- Service Rating Actions ----//
  GET_SERVICE_RATING_LIST_METHOD: "GET_SERVICE_RATING_LIST_METHOD",
  GET_SERVICE_RATING_LIST_SUCCESS: "GET_SERVICE_RATING_LIST_SUCCESS",
  GET_SERVICE_RATING_LIST_FAIL: "GET_SERVICE_RATING_LIST_FAIL",
  GET_SERVICE_RATING_LIST_CLEAR: "GET_SERVICE_RATING_LIST_CLEAR",
  // ----- Single user Details ---- //
  GET_SINGLE_USER_METHOD: "GET_SINGLE_USER_METHOD",
  GET_SINGLE_USER_SUCCESS: "GET_SINGLE_USER_SUCCESS",
  GET_SINGLE_USER_FAIL: "GET_SINGLE_USER_FAIL",
  GET_SINGLE_USER_CLEAR: "GET_SINGLE_USER_CLEAR",
  // ----- Payback Action ---- //
  GET_PAYBACK_LIST_METHOD: "GET_PAYBACK_LIST_METHOD",
  GET_PAYBACK_LIST_SUCCESS: "GET_PAYBACK_LIST_SUCCESS",
  GET_PAYBACK_LIST_FAIL: "GET_PAYBACK_LIST_FAIL",
  GET_PAYBACK_LIST_CLEAR: "GET_PAYBACK_LIST_CLEAR",

  // ----- Promo code history Action ---- //
  GET_PROMO_CODE_HISTORY_METHOD: "GET_PROMO_CODE_HISTORY_METHOD",
  GET_PROMO_CODE_HISTORY_SUCCESS: "GET_PROMO_CODE_HISTORY_SUCCESS",
  GET_PROMO_CODE_HISTORY_FAIL: "GET_PROMO_CODE_HISTORY_FAIL",
  GET_PROMO_CODE_HISTORY_CLEAR: "GET_PROMO_CODE_HISTORY_CLEAR",

  // ----- api logs list actions ---- //
  GET_API_LOGS_METHOD: "GET_API_LOGS_METHOD",
  GET_API_LOGS_SUCCESS: "GET_API_LOGS_SUCCESS",
  GET_API_LOGS_FAIL: "GET_API_LOGS_FAIL",
  GET_API_LOGS_CLEAR: "GET_API_LOGS_CLEAR",

  // ----- single api ---- //
  GET_SINGLE_API_LOG_METHOD: "GET_SINGLE_API_LOG_METHOD",
  GET_SINGLE_API_LOG_SUCCESS: "GET_SINGLE_API_LOG_SUCCESS",
  GET_SINGLE_API_LOG_FAIL: "GET_SINGLE_API_LOG_FAIL",
  GET_SINGLE_API_LOG_CLEAR: "GET_SINGLE_API_LOG_CLEAR",

  // ----- staff List ---- //
  GET_STAFF_LIST_METHOD: "GET_STAFF_LIST_METHOD",
  GET_STAFF_LIST_SUCCESS: "GET_STAFF_LIST_SUCCESS",
  GET_STAFF_LIST_FAIL: "GET_STAFF_LIST_FAIL",
  GET_STAFF_LIST_CLEAR: "GET_STAFF_LIST_CLEAR",

  //----- service rating -----//
  GET_SERVICE_REVIEWS_METHOD: "GET_SERVICE_REVIEWS_METHOD",
  GET_SERVICE_REVIEWS_SUCCESS: "GET_SERVICE_REVIEWS_SUCCESS",
  GET_SERVICE_REVIEWS_FAIL: "GET_SERVICE_REVIEWS_FAIL",
  GET_SERVICE_REVIEWS_CLEAR: "GET_SERVICE_REVIEWS_CLEAR",

  //----- single service rating -----//
  GET_SINGLE_SERVICE_REVIEWS_METHOD: "GET_SINGLE_SERVICE_REVIEWS_METHOD",
  GET_SINGLE_SERVICE_REVIEWS_SUCCESS: "GET_SINGLE_SERVICE_REVIEWS_SUCCESS",
  GET_SINGLE_SERVICE_REVIEWS_FAIL: "GET_SINGLE_SERVICE_REVIEWS_FAIL",
  GET_SINGLE_SERVICE_REVIEWS_CLEAR: "GET_SINGLE_SERVICE_REVIEWS_CLEAR",

  //----- user details review -----//
  GET_USER_REVIEWS_METHOD: "GET_USER_REVIEWS_METHOD",
  GET_USER_REVIEWS_SUCCESS: "GET_USER_REVIEWS_SUCCESS",
  GET_USER_REVIEWS_FAIL: "GET_USER_REVIEWS_FAIL",
  GET_USER_REVIEWS_CLEAR: "GET_USER_REVIEWS_CLEAR",

  //----- banner title  -----//
  GET_BANNER_TITLE_METHOD: "GET_BANNER_TITLE_METHOD",
  GET_BANNER_TITLE_SUCCESS: "GET_BANNER_TITLE_SUCCESS",
  GET_BANNER_TITLE_FAIL: "GET_BANNER_TITLE_FAIL",
  GET_BANNER_TITLE_CLEAR: "GET_BANNER_TITLE_CLEAR",

  GET_SINGLE_BANNER_TITLE_METHOD: "GET_SINGLE_BANNER_TITLE_METHOD",
  GET_SINGLE_BANNER_TITLE_SUCCESS: "GET_SINGLE_BANNER_TITLE_SUCCESS",
  GET_SINGLE_BANNER_TITLE_FAIL: "GET_SINGLE_BANNER_TITLE_FAIL",
  GET_SINGLE_BANNER_TITLE_CLEAR: "GET_SINGLE_BANNER_TITLE_CLEAR",
};
