import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_SERVICES } from "Redux/services/apiTypes";

// ----- Get Server Watcher -----
export function* watchGetServiceSaga() {
  yield takeLatest(actionTypes.GET_SERVICES_METHOD, getServiceSaga);
}
export function* watchGetSingleServiceSaga() {
  yield takeLatest(actionTypes.GET_SINGLE_SERVICE_METHOD, getSingleServiceSaga);
}

// ----- Saga -----
function* getServiceSaga(action) {
  try {
    let url = {
      type: API_SERVICES + action.payload.query,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SERVICES_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SERVICES_FAIL, payload: error });
  }
}

function* getSingleServiceSaga(action) {
  try {
    let url = {
      type: `${API_SERVICES}${action.payload.id}/`,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SINGLE_SERVICE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SINGLE_SERVICE_FAIL, payload: error });
  }
}
