import React from "react";
const CreateBooking = React.lazy(() =>
  import("components/ServiceRequest/createBooking/CreateBooking")
);
const Reviews = React.lazy(() => import("components/Reviews"));
const ServiceReviewDetail = React.lazy(() =>
  import("components/Reviews/serviceReviewsDetail/ServiceReviewDetail")
);
const SupplierServiceDetail = React.lazy(() =>
  import(
    "components/Supplier/supplierDetails/SupplierServiceDetail/SupplierServiceDetail"
  )
);
const CreatePromoCode = React.lazy(() =>
  import("components/PromoCode/CreatePromoCode")
);
const ApiLogs = React.lazy(() => import("components/APILogs"));
const ThirdCategory = React.lazy(() =>
  import("components/Category/ThirdCategory")
);
const PaybackRequest = React.lazy(() => import("components/PaybackRequest"));
const PromoCodeHistory = React.lazy(() =>
  import("components/PromoCode/PromoCodeDetails")
);
const UDetailsIndex = React.lazy(() => import("components/Users/UserDetails"));
const Refund = React.lazy(() => import("components/Refund"));
const Dashboard = React.lazy(() => import("components/Dashboard"));
const WithdrawalMoney = React.lazy(() => import("components/withdrawalMoney"));
const Users = React.lazy(() => import("components/Users"));
const CreateUser = React.lazy(() => import("components/Users/CreateUser"));
const Services = React.lazy(() => import("components/Services"));
const ServiceRequest = React.lazy(() => import("components/ServiceRequest"));
const Banner = React.lazy(() => import("components/Banner"));
const Notification = React.lazy(() => import("components/Notification"));
const Faq = React.lazy(() => import("components/Faq"));
const PromoCode = React.lazy(() => import("components/PromoCode"));
const Category = React.lazy(() => import("components/Category/MainCategory"));
const SubCategory = React.lazy(() => import("components/Category/SubCategory"));
const CreateNotification = React.lazy(() =>
  import("components/Notification/CreateNotification")
);
const CreateService = React.lazy(() =>
  import("components/Services/CreateService")
);
const ServiceDetails = React.lazy(() =>
  import("components/Services/ServiceDetails/ServiceDetails")
);
const ServiceRequestDetails = React.lazy(() =>
  import("components/ServiceRequest/ServiceRequestDetails")
);
const Supplier = React.lazy(() => import("components/Supplier"));
const CreateAdditionalFields = React.lazy(() =>
  import("components/Services/CreateAdditionalFields")
);
const SupplierDetails = React.lazy(() =>
  import("components/Supplier/supplierDetails/SupplierDetails")
);
const AssignSupplier = React.lazy(() =>
  import("components/Services/ServiceDetails/AssignSupplier")
);
const CreateSupplier = React.lazy(() =>
  import("components/Supplier/CreateSupplier")
);
const ApiLogDetail = React.lazy(() =>
  import("components/APILogs/apiLogDetails/ApiLogDetail")
);

const ScreenRoutes = [
  { path: "dashboard", name: "Dashboard", element: <Dashboard /> },
  { path: "users", name: "Users", element: <Users /> },
  {
    path: "user/create",
    name: "CreateUser",
    element: <CreateUser />,
  },
  {
    path: "services",
    name: "Services",
    element: <Services />,
  },
  {
    path: "serviceRequest",
    name: "ServiceRequest",
    element: <ServiceRequest />,
  },
  {
    path: "serviceRequest/:status",
    name: "ServiceRequest",
    element: <ServiceRequest />,
  },
  {
    path: "serviceRequest/details/:id",
    name: "ServiceRequestDetails",
    element: <ServiceRequestDetails />,
  },
  {
    path: "banner",
    name: "Banner",
    element: <Banner />,
  },

  {
    path: "notification",
    name: "Notification",
    element: <Notification />,
  },
  {
    path: "promoCode",
    name: "promoCode",
    element: <PromoCode />,
  },
  {
    path: "faq",
    name: "Faq",
    element: <Faq />,
  },
  {
    path: "category",
    name: "Category",
    element: <Category />,
  },
  {
    path: "category/subCategory/:id",
    name: "SubCategory",
    element: <SubCategory />,
  },
  {
    path: "notification/create",
    name: "CreateNotification",
    element: <CreateNotification />,
  },
  {
    path: "notification/update/:id",
    name: "CreateNotification",
    element: <CreateNotification />,
  },
  {
    path: "services/create",
    name: "CreateService",
    element: <CreateService />,
  },
  {
    path: "services/update/:id",
    name: "CreateService",
    element: <CreateService />,
  },
  {
    path: "services/:id",
    name: "ServiceDetails",
    element: <ServiceDetails />,
  },
  {
    path: "supplier",
    name: "Supplier",
    element: <Supplier />,
  },
  {
    path: "supplier/:id",
    name: "SupplierDetails",
    element: <SupplierDetails />,
  },
  {
    path: "service/AdditionalField",
    name: "CreateAdditionalFields",
    element: <CreateAdditionalFields />,
  },
  {
    path: "service/AdditionalField/update/:id",
    name: "CreateAdditionalFields",
    element: <CreateAdditionalFields />,
  },
  {
    path: "withdrawal",
    name: "WithdrawalMoney",
    element: <WithdrawalMoney />,
  },
  {
    path: "services/supplierservicedetails/:service/:provider",
    name: "SupplierServiceDetails",
    element: <SupplierServiceDetail />,
  },

  {
    path: "services/assignsupplier",
    name: "AssignSupplier",
    element: <AssignSupplier />,
  },
  {
    path: "users/:userId",
    name: "UserDetailsRequest",
    element: <UDetailsIndex />,
  },
  {
    path: "refund",
    name: "Refund",
    element: <Refund />,
  },

  {
    path: "supplier/create/",
    name: "createSupplier",
    element: <CreateSupplier />,
  },
  {
    path: "supplier/update/:id",
    name: "createSupplier",
    element: <CreateSupplier />,
  },
  {
    path: "payback/",
    name: "payback",
    element: <PaybackRequest />,
  },
  {
    path: "category/subCategory/thirdCategory/:id",
    name: "ThirdCategory",
    element: <ThirdCategory />,
  },
  {
    path: "",
    name: "CreateService",
    element: <CreateService />,
  },
  {
    path: "promoCode/:id",
    name: "Promo Code History",
    element: <PromoCodeHistory />,
  },
  {
    path: "logs",
    name: "API logs",
    element: <ApiLogs />,
  },
  {
    path: "logs/detail/:id",
    name: "Logs Details",
    element: <ApiLogDetail />,
  },
  {
    path: "promoCode/create",
    name: "Promo Code",
    element: <CreatePromoCode />,
  },
  {
    path: "promoCode/update/:id",
    name: "Promo Code Update",
    element: <CreatePromoCode />,
  },
  {
    path: "reviews",
    name: "Reviews",
    element: <Reviews />,
  },
  {
    path: "reviews/detail/:id",
    name: "Reviews Details",
    element: <ServiceReviewDetail />,
  },
  {
    path: "serviceRequest/createBooking",
    name: "Create Booking",
    element: <CreateBooking />,
  },
];

export default ScreenRoutes;
