import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_SERVICES } from "Redux/services/apiTypes";

export function* getServiceRatingListWatcher() {
  yield takeLatest(
    actionTypes.GET_SERVICE_RATING_LIST_METHOD,
    getServiceRatingListWorker
  );
}

function* getServiceRatingListWorker(action) {
  try {
    let url = {
      type: API_SERVICES + action.payload.query,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({
      type: actionTypes.GET_SERVICE_RATING_LIST_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SERVICE_RATING_LIST_FAIL,
      payload: error,
    });
  }
}
