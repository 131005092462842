import { PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  staffLoading: false,
  staffError: "",
  staffRes: PAGINATION_INITIAL_LIST_RES,
  isStaffResSuccess: false,
};

export default function staffReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_STAFF_LIST_METHOD:
      return {
        ...state,
        staffLoading: true,
        staffError: "",
        isStaffResSuccess: false,
      };

    case actionTypes.GET_STAFF_LIST_SUCCESS:
      return {
        ...state,
        staffLoading: false,
        staffRes: action.payload,
        staffError: "",
        isStaffResSuccess: true,
      };

    case actionTypes.GET_STAFF_LIST_FAIL:
      return {
        ...state,
        staffLoading: false,
        staffError: action.payload,
        isStaffResSuccess: false,
      };

    case actionTypes.GET_STAFF_LIST_CLEAR:
      return {
        ...state,
        staffLoading: false,
        staffError: "",
        staffRes: PAGINATION_INITIAL_LIST_RES,
        isStaffResSuccess: false,
      };

    default:
      return state;
  }
}
