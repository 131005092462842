import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_CATEGORY } from "Redux/services/apiTypes";

// ----- Get Category Watcher -----
export function* watchGetCategorySaga() {
  yield takeLatest(actionTypes.GET_CATEGORY_METHOD, getCategorySaga);
}
export function* watchGetSubCategorySaga() {
  yield takeLatest(actionTypes.GET_SUB_CATEGORY_METHOD, getSubCategorySaga);
}

export function* watchGetThirdCategorySaga() {
  yield takeLatest(actionTypes.GET_THIRD_CATEGORY_METHOD, getThirdCategorySaga);
}

// ----- Saga -----
function* getCategorySaga(action) {
  try {
    let url = {
      type: `${API_CATEGORY}${action.payload.query}`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_CATEGORY_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_CATEGORY_FAIL, payload: error });
  }
}

function* getSubCategorySaga(action) {
  try {
    let url = {
      type: `${API_CATEGORY}${action.payload.query}`,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SUB_CATEGORY_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SUB_CATEGORY_FAIL, payload: error });
  }
}

function* getThirdCategorySaga(action) {
  try {
    let url = {
      type: `${API_CATEGORY}${action.payload.query}`,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_THIRD_CATEGORY_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_THIRD_CATEGORY_FAIL, payload: error });
  }
}
