import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_SUGGESTION } from "Redux/services/apiTypes";

// ----- Get Suggestion Watcher -----
export function* watchGetSuggestionSaga() {
  yield takeLatest(actionTypes.GET_SUGGESTION_METHOD, getSuggestionSaga);
}
export function* watchGetSingleSuggestionSaga() {
  yield takeLatest(
    actionTypes.GET_SINGLE_SUGGESTION_METHOD,
    getSingleSuggestionSaga
  );
}

// ----- Saga -----
function* getSuggestionSaga(action) {
  try {
    let url = {
      type: API_SUGGESTION,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SUGGESTION_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SUGGESTION_FAIL, payload: error });
  }
}

function* getSingleSuggestionSaga(action) {
  try {
    let url = {
      type: `${API_SUGGESTION}${action.payload}/`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({
      type: actionTypes.GET_SINGLE_SUGGESTION_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({ type: actionTypes.GET_SINGLE_SUGGESTION_FAIL, payload: error });
  }
}
