import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { SERVICE_REVIEWS } from "Redux/services/apiTypes";

// ----- Get API_LOGS Watcher -----
export function* watchSingleServiceReviewsSaga() {
  yield takeLatest(
    actionTypes.GET_SINGLE_SERVICE_REVIEWS_METHOD,
    getSingleServiceReviewsSaga
  );
}

// ----- Saga -----
function* getSingleServiceReviewsSaga(action) {
  try {
    let url = {
      type: SERVICE_REVIEWS + action.payload + "/",
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({
      type: actionTypes.GET_SINGLE_SERVICE_REVIEWS_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_SERVICE_REVIEWS_FAIL,
      payload: error,
    });
  }
}
