import Links from "./Links";
import {
  AccountBalance,
  AttachMoney,
  Category,
  CurrencyExchange,
  Dashboard,
  Group,
  HomeRepairService,
  LiveHelp,
  LocalOffer,
  ManageAccounts,
  NotificationsActive,
  Person,
  ViewCarousel,
} from "@mui/icons-material";
import ReviewsIcon from "@mui/icons-material/Reviews";
import ApiIcon from "@mui/icons-material/Api";
import { shallowEqual, useSelector } from "react-redux";
import { roles } from "utils/constant";

const adminLinksData = [
  {
    routes: [
      {
        name: "Dashboard",
        label: "Dashboard",
        link: "/admin/dashboard",
        icon: <Dashboard fontSize="small" />,
      },
      {
        name: "Users",
        label: "Customers",
        link: "/admin/users",
        icon: <Person fontSize="small" />,
      },
      {
        name: "Supplier",
        label: "Suppliers",
        link: "/admin/supplier",
        icon: <Group fontSize="small" />,
      },
      {
        name: "Category",
        label: "Category",
        link: "/admin/category",
        icon: <Category fontSize="small" />,
      },
      {
        name: "Services",
        label: "Services",
        link: "/admin/services",
        icon: <HomeRepairService fontSize="small" />,
      },
      {
        name: "ServiceRequest",
        label: "Service Request",
        link: "/admin/serviceRequest",
        icon: <ManageAccounts fontSize="small" />,
      },
      {
        name: "Refund",
        label: "Refund",
        link: "/admin/Refund",
        icon: <CurrencyExchange fontSize="small" />,
      },
      {
        name: "Banner",
        label: "Banner",
        link: "/admin/banner",
        icon: <ViewCarousel fontSize="small" />,
      },

      // {
      //   name: "Suggestion",
      //   label: "Suggestion",
      //   link: "/admin/suggestion",
      //   icon: <TipsAndUpdates fontSize="small" />,
      // },
      {
        name: "Notification",
        label: "Notification",
        link: "/admin/notification",
        icon: <NotificationsActive fontSize="small" />,
      },
      {
        name: "PromoCode",
        label: "Promocode",
        link: "/admin/promoCode",
        icon: <LocalOffer fontSize="small" />,
      },
      {
        name: "Payout Request",
        label: "Payout Request",
        link: "/admin/withdrawal",
        icon: <AttachMoney fontSize="small" />,
      },
      {
        name: "Payback Request",
        label: "Payback Request",
        link: "/admin/payback",
        icon: <AccountBalance fontSize="small" />,
      },
      {
        name: "Faq",
        label: "FAQ",
        link: "/admin/faq",
        icon: <LiveHelp fontSize="small" />,
      },
      {
        name: "Reviews",
        label: "Reviews",
        link: "/admin/reviews",
        icon: <ReviewsIcon fontSize="small" />,
      },
      {
        name: "API Logs",
        label: "API Logs",
        link: "/admin/logs",
        icon: <ApiIcon fontSize="small" />,
      },
    ],
  },
];

const providerLinksData = [
  {
    routes: [
      {
        name: "Dashboard",
        label: "Dashboard",
        link: "/admin/dashboard",
        icon: <Dashboard fontSize="small" />,
      },

      {
        name: "Service Request",
        label: "Service Request",
        link: "/admin/serviceRequest",
        icon: <ManageAccounts fontSize="small" />,
      },
      {
        name: "Notification",
        label: "Notification",
        link: "/admin/notification",
        icon: <NotificationsActive fontSize="small" />,
      },
    ],
  },
];

const LinkData = () => {
  const { res } = useSelector(
    (state) => ({
      res: state.authReducer,
    }),
    shallowEqual
  );
  return (
    <>
      {res.userRole === roles.is_staff &&
        adminLinksData.map((link, index) => <Links key={index} link={link} />)}
      {res.userRole === roles.is_service_provider &&
        providerLinksData.map((link, index) => (
          <Links key={index} link={link} />
        ))}
    </>
  );
};
export default LinkData;
