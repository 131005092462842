import { PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  serviceReviewsLoading: false,
  serviceReviewsError: "",
  serviceReviewsRes: PAGINATION_INITIAL_LIST_RES,
  isServiceReviewsResSuccess: false,
};

export default function serviceReviewsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_SERVICE_REVIEWS_METHOD:
      return {
        ...state,
        serviceReviewsLoading: true,
        serviceReviewsError: "",
        isServiceReviewsResSuccess: false,
      };

    case actionTypes.GET_SERVICE_REVIEWS_SUCCESS:
      return {
        ...state,
        serviceReviewsLoading: false,
        serviceReviewsRes: action.payload,
        serviceReviewsError: "",
        isServiceReviewsResSuccess: true,
      };

    case actionTypes.GET_SERVICE_REVIEWS_FAIL:
      return {
        ...state,
        serviceReviewsLoading: false,
        serviceReviewsError: action.payload,
        isServiceReviewsResSuccess: false,
      };

    case actionTypes.GET_SERVICE_REVIEWS_CLEAR:
      return {
        ...state,
        serviceReviewsLoading: false,
        serviceReviewsError: "",
        serviceReviewsRes: PAGINATION_INITIAL_LIST_RES,
        isServiceReviewsResSuccess: false,
      };

    default:
      return state;
  }
}
