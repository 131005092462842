import { combineReducers } from "redux";
import authReducer from "./authReducer";
import userReducer from "./userReducer";
import serviceReducer from "./serviceReducer";
import serviceRequestReducer from "./serviceRequestReducer";
import bannerReducer from "./bannerReducer";
import suggestionReducer from "./suggestionReducer";
import notificationReducer from "./notificationReducer";
import promoCodeReducer from "./promoCodeReducer";
import faqReducer from "./faqReducer";
import categoryReducer from "./categoryReducer";
import sessionReducer from "./sessionReducer";
import supplierReducer from "./supplierReducer";
import dashboardReducer from "./dashboardReducer";
import withdrawalListReducer from "./withdrawalListReducer";
import refundListReducer from "./refundListReducer";
import ServiceRatingListReducer from "./serviceRatingReducer";
import { actionTypes } from "Redux/services/actionTypes";
import paybackListReducer from "./paybackListReducer";
import promoCodeHistoryReducer from "./promoCodeHistoryReducer";
import apiLogsReducer from "./apiLogsReducer";
import singleApiLogReducer from "./singleApiLogReducer";
import staffReducer from "./staffReducer";
import serviceReviewsReducer from "./serviceReviewsReducer";
import singleServiceReviewsReducer from "./singleServiceReviewsReducer";
import userReviewsReducer from "./userReviewsReducer";
import bannerTitleReducer from "./bannerTitleReducer";

const appReducer = combineReducers({
  authReducer,
  userReducer,
  serviceReducer,
  serviceRequestReducer,
  bannerReducer,
  suggestionReducer,
  notificationReducer,
  promoCodeReducer,
  faqReducer,
  categoryReducer,
  sessionReducer,
  supplierReducer,
  dashboardReducer,
  withdrawalListReducer,
  refundListReducer,
  ServiceRatingListReducer,
  paybackListReducer,
  promoCodeHistoryReducer,
  apiLogsReducer,
  singleApiLogReducer,
  staffReducer,
  serviceReviewsReducer,
  singleServiceReviewsReducer,
  userReviewsReducer,
  bannerTitleReducer,
});
const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT_USER_METHOD) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
