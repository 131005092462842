import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  serviceRatingLoading: false,
  serviceRatingListError: "",
  serviceRatingListRes: "",
};

export default function ServiceRatingListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_SERVICE_RATING_LIST_METHOD:
      return {
        ...state,
        serviceRatingLoading: true,
        serviceRatingListError: "",
      };

    case actionTypes.GET_SERVICE_RATING_LIST_SUCCESS:
      return {
        ...state,
        serviceRatingLoading: false,
        serviceRatingListRes: action.payload,
        serviceRatingListError: "",
      };

    case actionTypes.GET_SERVICE_RATING_LIST_FAIL:
      return {
        ...state,
        serviceRatingLoading: false,
        serviceRatingListError: action.payload,
      };

    case actionTypes.GET_SERVICE_RATING_LIST_CLEAR:
      return {
        ...state,
        serviceRatingLoading: false,
        serviceRatingListError: action.payload,
        serviceRatingListRes: "",
      };
    default:
      return state;
  }
}
