import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_LOGS } from "Redux/services/apiTypes";

// ----- Get API_LOGS Watcher -----
export function* watchSingleApiLogsSaga() {
  yield takeLatest(actionTypes.GET_SINGLE_API_LOG_METHOD, getSingleApiLogsSaga);
}

// ----- Saga -----
function* getSingleApiLogsSaga(action) {
  try {
    let url = {
      type: API_LOGS + action.payload + "/",
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SINGLE_API_LOG_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SINGLE_API_LOG_FAIL, payload: error });
  }
}
