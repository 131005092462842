import React from "react";
import { Button, CircularProgress, useTheme } from "@mui/material";

const CButton = ({ title, onClick, style, icon, type, disable, isLoading }) => {
  const theme = useTheme();

  return (
    <Button
      disabled={isLoading ? true : disable}
      onClick={onClick}
      sx={{
        textTransform: "none",
        background: disable ? "#b9b9b9" : theme.palette.primary.main,
        color: "white",
        "&:hover": {
          background: theme.palette.primary.dark,
        },
        my: 2,
        fontWeight: 500,
        px: 3,
        py: 1,
        fontSize: 15,
        borderRadius: "5px",
        ...style,
      }}
      endIcon={icon}
      type="button"
    >
      {title}
      {isLoading && (
        <CircularProgress
          size={24}
          sx={{
            color: "white",
            position: "absolute",
            top: "50%",
            left: "50%",
            marginTop: "-12px",
            marginLeft: "-12px",
          }}
        />
      )}
    </Button>
  );
};

export default CButton;
