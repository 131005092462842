import { PAGINATION_INITIAL_LIST_RES, initialTableData } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  bannerLoading: false,
  isBannerResSuccess: false,
  bannerError: "",
  bannerRes: PAGINATION_INITIAL_LIST_RES,

  singleBannerLoading: false,
  isSingleBannerResSuccess: false,
  singleBannerError: "",
  singleBannerRes: "",
};

export default function bannerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_BANNER_METHOD:
      return {
        ...state,
        bannerLoading: true,
        bannerError: "",
        isBannerResSuccess: true,
      };

    case actionTypes.GET_BANNER_SUCCESS:
      return {
        ...state,
        bannerLoading: false,
        bannerRes: action.payload,
        bannerError: "",
        isBannerResSuccess: false,
      };

    case actionTypes.GET_BANNER_FAIL:
      return {
        ...state,
        bannerLoading: false,
        bannerError: action.payload,
        isBannerResSuccess: false,
      };

    case actionTypes.GET_BANNER_CLEAR:
      return {
        ...state,
        bannerLoading: false,
        bannerError: "",
        bannerRes: initialTableData,
        isBannerResSuccess: false,
      };

    ///////////////////////////////////////////////////////

    case actionTypes.GET_SINGLE_BANNER_METHOD:
      return {
        ...state,
        singleBannerLoading: true,
        singleBannerError: "",
        isSingleBannerResSuccess: false,
      };

    case actionTypes.GET_SINGLE_BANNER_SUCCESS:
      return {
        ...state,
        singleBannerLoading: false,
        singleBannerRes: action.payload,
        singleBannerError: "",
        isSingleBannerResSuccess: true,
      };

    case actionTypes.GET_SINGLE_BANNER_FAIL:
      return {
        ...state,
        singleBannerLoading: false,
        singleBannerError: action.payload,
        isSingleBannerResSuccess: false,
      };

    case actionTypes.GET_SINGLE_BANNER_CLEAR:
      return {
        ...state,
        singleBannerLoading: false,
        singleBannerError: "",
        singleBannerRes: "",
        isSingleBannerResSuccess: false,
      };

    default:
      return state;
  }
}
