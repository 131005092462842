import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { Drawer, DrawerHeader } from "./layout.style";
import LinkData from "./LinkData";

const SideBar = ({ open, handleDrawer }) => {
  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawer}>
          {open ? (
            <ChevronLeft fontSize="large" sx={{ color: "white" }} />
          ) : (
            <ChevronRight fontSize="large" sx={{ color: "white" }} />
          )}
        </IconButton>
      </DrawerHeader>

      <LinkData />
    </Drawer>
  );
};

export default SideBar;
