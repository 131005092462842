import { createTheme } from "@mui/material/styles";

const Themes = (mode) => {
  const getDesignTokens = (mode) => ({
    palette: {
      mode,
      primary: {
        main: "#3160d4",
      },
      secondary: {
        main: "#a1f3b5",
      },
    },
  });

  const theme = createTheme(getDesignTokens(mode), [mode]);

  return theme;
};

export default Themes;
