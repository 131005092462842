import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { BANNER_TITLE } from "Redux/services/apiTypes";

// ----- Get Banner Watcher -----
export function* watchGetBannerTitleSaga() {
  yield takeLatest(actionTypes.GET_BANNER_TITLE_METHOD, getBannerTitleSaga);
}
export function* watchGetSingleBannerTitleSaga() {
  yield takeLatest(
    actionTypes.GET_SINGLE_BANNER_TITLE_METHOD,
    getSingleBannerTitleSaga
  );
}

// ----- Saga -----
function* getBannerTitleSaga(action) {
  try {
    let url = {
      type: `${BANNER_TITLE}${action.payload.query}`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_BANNER_TITLE_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_BANNER_TITLE_FAIL, payload: error });
  }
}
function* getSingleBannerTitleSaga(action) {
  try {
    let url = {
      type: `${BANNER_TITLE}${action.payload}/`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({
      type: actionTypes.GET_SINGLE_BANNER_TITLE_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_SINGLE_BANNER_TITLE_FAIL,
      payload: error,
    });
  }
}
