import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  singleApiLogLoading: false,
  singleApiLogError: "",
  singleApiLogRes: null,
  isSingleApiLogResSuccess: false,
};

export default function singleApiLogReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_SINGLE_API_LOG_METHOD:
      return {
        ...state,
        singleApiLogLoading: true,
        singleApiLogError: "",
        isSingleApiLogResSuccess: false,
      };

    case actionTypes.GET_SINGLE_API_LOG_SUCCESS:
      return {
        ...state,
        singleApiLogLoading: false,
        singleApiLogRes: action.payload,
        singleApiLogError: "",
        isSingleApiLogResSuccess: true,
      };

    case actionTypes.GET_SINGLE_API_LOG_FAIL:
      return {
        ...state,
        singleApiLogLoading: false,
        singleApiLogError: action.payload,
        isSingleApiLogResSuccess: false,
      };

    case actionTypes.GET_SINGLE_API_LOG_CLEAR:
      return {
        ...state,
        singleApiLogLoading: false,
        singleApiLogError: "",
        singleApiLogRes: null,
        isSingleApiLogResSuccess: false,
      };

    default:
      return state;
  }
}
