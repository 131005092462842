export const USER_TOKEN = "token";
export const IS_KEEP_ME_LOGGED_IN = "isKeepMeLoggedIn";
export const USER_ROLE = "userRole";

export const roles = {
  is_service_provider: "is_service_provider",
  is_staff: "is_staff",
};

export const initialTableData = {
  next: null,
  previous: null,
  count: 0,
  results: [],
};

export const SERVICE_FIELD_TYPE = {
  INTEGER: "IntegerField",
  TEXT: "TextField",
  CHARACTER: "CharField",
  BOOLEAN: "BooleanField",
  OPTIONS: "OptionField",
  DROPDOWN: "DropDownField",
  PRICE_COMBINATION: "PriceCombinationField",
  SECTION: "SectionField",
};

export const BANNER_DROPDOWN = [
  {
    display: "None",
    key: "none",
  },
  {
    display: "Service",
    key: "service",
  },
  {
    display: "Url",
    key: "url",
  },
];

export const SERVICE_FIELD_TYPE_OPTIONS = [
  {
    display: "Integer (Plus Minus button)",
    key: SERVICE_FIELD_TYPE.INTEGER,
  },
  {
    display: "Text Field (Description box)",
    key: SERVICE_FIELD_TYPE.TEXT,
  },
  {
    display: "Char Field (Single line text box)",
    key: SERVICE_FIELD_TYPE.CHARACTER,
  },
  {
    display: "Boolean Field (On Off switch)",
    key: SERVICE_FIELD_TYPE.BOOLEAN,
  },
  {
    display: "Option Field (Options without price calculations)",
    key: SERVICE_FIELD_TYPE.OPTIONS,
  },
  {
    display: "Price Combination Field (Options with price calculations)",
    key: SERVICE_FIELD_TYPE.PRICE_COMBINATION,
  },
  {
    display: "Drop Down Field (Dropdown)",
    key: SERVICE_FIELD_TYPE.DROPDOWN,
  },
  {
    display: "Section Filed (Separator)",
    key: SERVICE_FIELD_TYPE.SECTION,
  },
];

export const SESSIONS_TYPE = {
  AFTERNOON: "afternoon",
  MORNING: "morning",
  EVENING: "evening",
};

export const SESSIONS_TYPE_OPTIONS = [
  {
    display: "Morning",
    key: SESSIONS_TYPE.MORNING,
  },
  {
    display: "Afternoon",
    key: SESSIONS_TYPE.AFTERNOON,
  },
  {
    display: "Evening",
    key: SESSIONS_TYPE.EVENING,
  },
];

export const SERVICE_SESSION_DURATION_OPTIONS = [
  {
    display: "1 Hour",
    key: 60,
  },
  {
    display: "2 Hours",
    key: 120,
  },
  {
    display: "4 Hours",
    key: 240,
  },
];

export const SERVICE_SESSION_BOOKED_BEFORE_OPTIONS = [
  {
    display: "30 Mins",
    key: 30,
  },
  {
    display: "1 Hour",
    key: 60,
  },
  {
    display: "2 Hours",
    key: 120,
  },
  {
    display: "6 Hours",
    key: 360,
  },
  {
    display: "12 Hours",
    key: 720,
  },
  {
    display: "24 Hours",
    key: 1440,
  },
  {
    display: "48 Hours",
    key: 2880,
  },
];

export const PAGINATION_INITIAL_LIST_RES = {
  count: null,
  next: null,
  previous: null,
  results: [],
};

//SERVICE CONSTANTS
export const SERVICE_STATUS = {
  CREATED: "created",
  PAID: "paid",
  ACCEPTED: "accepted",
  IN_TRANSIT: "in_transit",
  AT_DESTINATION: "at_destination",
  COMPLETED_BY_PROVIDER: "completed_by_provider",
  COMPLETED: "completed",
  CANCELED: "cancelled",
  CANCELED_DUE_PAYMENT: "cancelled_due_Payment",
  CANCELED_BY_SUPPLIER: "cancelled_by_supplier",
  CANCELED_BY_CLIENT: "cancelled_by_client",
  IN_PROGRESS: "in_progress",
};

//SERVICE CONSTANTS
// export const SERVICE_STATUS_TYPES = [
//   { key: "created", display: "Created" },
//   { key: "paid", display: "Paid" },
//   { key: "accepted", display: "Accepted" },
//   { key: "in_transit", display: "In Transit" },
//   { key: "at_destination", display: "At Destination" },
//   { key: "completed_by_provider", display: "Completed By Provider" },
//   { key: "completed", display: "Completed" },
//   { key: "canceled", display: "Canceled" },
//   { key: "canceled_due_Payment", display: "Canceled Due Payment" },
//   { key: "canceled_by_supplier", display: "Canceled By Supplier" },
//   { key: "canceled_by_client", display: "Canceled By Client" },
// ];

export const WITHDRAWAL_TYPES = [
  { key: "&is_approved=false", display: "Pending" },
  { key: "", display: "All" },
];

export const CHART = [
  { key: "this_month", display: "This Month" },
  { key: "last_month", display: "Last Month" },
  { key: "this_year", display: "This Year" },
  { key: "last_year", display: "Last Year" },
  { key: "life_time", display: "Life Time" },
];
export const SUPPLIER_TYPES = [
  { key: "&is_approved=true", display: "Approved" },
  { key: "&is_approved=false", display: "Pending" },
];

export const USER_ACTIVE_DELETED = [
  { key: "&is_deleted=false", display: "Active" },
  { key: "&is_deleted=true", display: "Deleted" },
];

export const AREAS_SERVED = [
  { key: "&is_deleted=true", display: "Colombo" },
  { key: "&is_deleted=true", display: "Mount Lavinia" },
  { key: "&is_deleted=true", display: "Maharagama" },
  { key: "&is_deleted=true", display: "Matara" },
  { key: "&is_deleted=true", display: "Galle" },
  { key: "&is_deleted=true", display: "Panadura" },
];

export const RESPONSE_CODE = [
  { key: "", display: "All" },
  { key: "&response_status_code=200", display: "200" },
  { key: "&response_status_code=201", display: "201" },
  { key: "&response_status_code=202", display: "202" },
  { key: "&response_status_code=204", display: "204" },
  { key: "&response_status_code=301", display: "301" },
  { key: "&response_status_code=302", display: "302" },
  { key: "&response_status_code=303", display: "303" },
  { key: "&response_status_code=304", display: "304" },
  { key: "&response_status_code=307", display: "307" },
  { key: "&response_status_code=400", display: "400" },
  { key: "&response_status_code=401", display: "401" },
  { key: "&response_status_code=403", display: "403" },
  { key: "&response_status_code=404", display: "404" },
  { key: "&response_status_code=405", display: "405" },
  { key: "&response_status_code=406", display: "406" },
  { key: "&response_status_code=412", display: "412" },
  { key: "&response_status_code=415", display: "415" },
  { key: "&response_status_code=500", display: "500" },
  { key: "&response_status_code=501", display: "501" },
  { key: "&response_status_code=502", display: "502" },
];

export const REQUEST_METHOD = [
  { key: "", display: "All" },
  { key: "&request_method=GET", display: "GET" },
  { key: "&request_method=POST", display: "POST" },
  { key: "&request_method=PATCH", display: "PATCH" },
  { key: "&request_method=DELETE", display: "DELETE" },
  { key: "&request_method=HEAD", display: "HEAD" },
];

export const GOOGLE_MAP_BASE_URL = "https://maps.googleapis.com/maps/api/";

export const MAP_ADDRES_FROM_LATLONG =
  GOOGLE_MAP_BASE_URL + "geocode/json?latlng=";

export const MAP_ADDRESS_FROM_TEXT =
  GOOGLE_MAP_BASE_URL + "place/textsearch/json?query=";

export const GOOGLE_MAP_API_KEY = "AIzaSyAvjKwFLs8q7GiEZvqRoxbucvVtSBy_7UI";

export const SERVICE_STATUS_VALUE = {
  CREATED: "created",
  ACCEPTED: "accepted",
  IN_TRANSIT: "in_transit",
  AT_DESTINATION: "at_destination",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  CANCELLED_DUE_PAYMENT: "cancelled_due_payment",
  CANCELLED_BY_SUPPLIER: "cancelled_by_supplier",
  CANCELLED_BY_CLIENT: "cancelled_by_client",
};

export const SERVICE_STATUS_TYPES = [
  { key: SERVICE_STATUS_VALUE.CREATED, display: "Created" },
  { key: SERVICE_STATUS_VALUE.ACCEPTED, display: "Accepted" },
  { key: SERVICE_STATUS_VALUE.IN_TRANSIT, display: "In Transit" },
  { key: SERVICE_STATUS_VALUE.AT_DESTINATION, display: "At Destination" },
  { key: SERVICE_STATUS_VALUE.IN_PROGRESS, display: "In Progress" },
  { key: SERVICE_STATUS_VALUE.COMPLETED, display: "Completed" },
  { key: SERVICE_STATUS_VALUE.CANCELLED, display: "Cancelled" },
  {
    key: SERVICE_STATUS_VALUE.CANCELLED_DUE_PAYMENT,
    display: "Cancelled Due Payment",
  },
  {
    key: SERVICE_STATUS_VALUE.CANCELLED_BY_SUPPLIER,
    display: "Cancelled By Supplier",
  },
  {
    key: SERVICE_STATUS_VALUE.CANCELLED_BY_CLIENT,
    display: "Cancelled By Client",
  },
];

export const BANNER_POSITION = [
  { key: "1", display: "1 (Main Banner)" },
  { key: "2", display: "2 (Promotional Banner)" },
];

export const BANNER_TITLE_POSITION = [
  { key: "2", display: "2 (Promotional Banner Title)" },
];
