import { PAGINATION_INITIAL_LIST_RES, initialTableData } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  userReviewsLoading: false,
  isUserReviewsResSuccess: false,
  userReviewsError: "",
  userReviewsRes: PAGINATION_INITIAL_LIST_RES,
};

export default function userReviewsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_USER_REVIEWS_METHOD:
      return {
        ...state,
        userReviewsLoading: true,
        userReviewsError: "",
        isUserReviewsResSuccess: true,
      };

    case actionTypes.GET_USER_REVIEWS_SUCCESS:
      return {
        ...state,
        userReviewsLoading: false,
        userReviewsRes: action.payload,
        userReviewsError: "",
        isUserReviewsResSuccess: false,
      };

    case actionTypes.GET_USER_REVIEWS_FAIL:
      return {
        ...state,
        userReviewsLoading: false,
        userReviewsError: action.payload,
        isUserReviewsResSuccess: false,
      };

    case actionTypes.GET_USER_REVIEWS_CLEAR:
      return {
        ...state,
        userReviewsLoading: false,
        userReviewsError: "",
        userReviewsRes: initialTableData,
        isUserReviewsResSuccess: false,
      };

    default:
      return state;
  }
}
