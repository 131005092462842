import { actionTypes } from "../services/actionTypes";

const initDashboardState = {
  dashboardData: {},
  dashboardFetching: false,
  dashboardError: {},
  dashboardSuccess: false,
};

export default function dashboardReducer(state = initDashboardState, action) {
  switch (action.type) {
    case actionTypes.GET_DASHBOARD_DATA_METHOD:
      return {
        ...state,
        dashboardFetching: true,
        dashboardSuccess: false,
      };
    case actionTypes.GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboardFetching: false,
        dashboardSuccess: true,
        dashboardData: action.payload,
      };
    case actionTypes.GET_DASHBOARD_DATA_FAIL:
      return {
        ...state,
        dashboardFetching: false,
        dashboardSuccess: false,
        dashboardError: action.payload,
      };
    case actionTypes.GET_DASHBOARD_DATA_CLEAR:
      return {
        ...state,
        initDashboardState,
      };

    /////////////////////////////////////////////////////////////////////

    default:
      return state;
  }
}
