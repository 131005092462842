import { initialTableData } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  serviceLoading: false,
  isServiceResSuccess: false,
  serviceError: "",
  serviceRes: "",

  singleServiceLoading: false,
  isSingleServiceResSuccess: false,
  singleServiceError: "",
  singleServiceRes: "",
};

export default function serviceReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_SERVICES_METHOD:
      return {
        ...state,
        serviceLoading: true,
        serviceError: "",
        isServiceResSuccess: false,
      };

    case actionTypes.GET_SERVICES_SUCCESS:
      return {
        ...state,
        serviceLoading: false,
        serviceRes: action.payload,
        serviceError: "",
        isServiceResSuccess: true,
      };

    case actionTypes.GET_SERVICES_FAIL:
      return {
        ...state,
        serviceLoading: false,
        isServiceResSuccess: false,
        serviceError: action.payload,
      };

    case actionTypes.GET_SERVICES_CLEAR:
      return {
        ...state,
        serviceLoading: false,
        serviceError: "",
        serviceRes: "",
        isServiceResSuccess: false,
      };

    /////////////////////////////////

    case actionTypes.GET_SINGLE_SERVICE_METHOD:
      return {
        ...state,
        singleServiceLoading: true,
        singleServiceError: "",
        isSingleServiceResSuccess: false,
      };

    case actionTypes.GET_SINGLE_SERVICE_SUCCESS:
      return {
        ...state,
        singleServiceLoading: false,
        singleServiceRes: action.payload,
        singleServiceError: "",
        isSingleServiceResSuccess: true,
      };

    case actionTypes.GET_SINGLE_SERVICE_FAIL:
      return {
        ...state,
        isSingleServiceResSuccess: false,
        singleServiceLoading: false,
        singleServiceError: action.payload,
      };

    case actionTypes.GET_SINGLE_SERVICE_CLEAR:
      return {
        ...state,
        singleServiceLoading: false,
        singleServiceError: "",
        singleServiceRes: "",
        isSingleServiceResSuccess: false,
      };

    default:
      return state;
  }
}
