import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_WITHDRAWAL_REQUEST } from "Redux/services/apiTypes";

export function* getWithdrawalListWatcher() {
  yield takeLatest(
    actionTypes.GET_WITHDRAWAL_LIST_METHOD,
    getWithdrawalListWorker
  );
}

function* getWithdrawalListWorker(action) {
  try {
    let url = {
      type: API_WITHDRAWAL_REQUEST + action.payload.query,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_WITHDRAWAL_LIST_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_WITHDRAWAL_LIST_FAIL, payload: error });
  }
}
