import { PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  promoCodeHistoryLoading: false,
  promoCodeHistoryError: "",
  promoCodeHistoryRes: PAGINATION_INITIAL_LIST_RES,
  isPromoCodeHistoryResSuccess: false,
};

export default function promoCodeHistoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_PROMO_CODE_HISTORY_METHOD:
      return {
        ...state,
        promoCodeHistoryLoading: true,
        promoCodeHistoryError: "",
        isPromoCodeHistoryResSuccess: false,
      };

    case actionTypes.GET_PROMO_CODE_HISTORY_SUCCESS:
      return {
        ...state,
        promoCodeHistoryLoading: false,
        promoCodeHistoryRes: action.payload,
        promoCodeHistoryError: "",
        isPromoCodeHistoryResSuccess: true,
      };

    case actionTypes.GET_PROMO_CODE_HISTORY_FAIL:
      return {
        ...state,
        promoCodeHistoryLoading: false,
        promoCodeHistoryError: action.payload,
        isPromoCodeHistoryResSuccess: false,
      };

    case actionTypes.GET_PROMO_CODE_HISTORY_CLEAR:
      return {
        ...state,
        promoCodeHistoryLoading: false,
        promoCodeHistoryError: "",
        promoCodeHistoryRes: PAGINATION_INITIAL_LIST_RES,
        isPromoCodeHistoryResSuccess: false,
      };

    default:
      return state;
  }
}
