import Cookies from "js-cookie";
import { loginUserAction, logoutUserAction } from "Redux/actions/authAction";
import ReduxStore from "../Redux/store";
import {
  GOOGLE_MAP_API_KEY,
  MAP_ADDRES_FROM_LATLONG,
  MAP_ADDRESS_FROM_TEXT,
  roles,
  USER_ROLE,
  USER_TOKEN,
} from "./constant";
import axios from "axios";

//Get Error Message
export function getErrorMessage(data) {
  let returnError = [];
  if (typeof data === "string") return data;
  if (Array.isArray(data)) {
    for (let i = 0; i < data.length; i++) {
      let errorValue = "";
      for (const [key, value] of Object.entries(data[i])) {
        errorValue += `${key.toUpperCase()}: ${value} `;
      }
      returnError.push(errorValue + "|  ");
      errorValue = "";
    }
    return returnError;
  } else {
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        for (let i = 0; i < value.length; i++) {
          const element = `${key.toUpperCase()}: ${value[i]}`;
          return element;
        }
      } else return `${key.toUpperCase()}: ${value}`;
    }
  }
}

// login user
export const loginUser = (res) => {
  let role = res.is_staff
    ? roles.is_staff
    : res.is_service_provider
    ? roles.is_service_provider
    : null;

  ReduxStore.dispatch(loginUserAction(role));
  Cookies.set(USER_TOKEN, res.token);
  Cookies.set(USER_ROLE, role);
  // window.location.href = "/admin/dashboard";
};

// logout user
export const logoutUser = () => {
  ReduxStore.dispatch(logoutUserAction());
  Cookies.remove(USER_TOKEN);
  Cookies.remove(USER_ROLE);
  localStorage.clear();
};

// get image
export const getImageUrl = (path) => {
  if (path?.includes("http")) {
    return path;
  } else {
    return process.env.REACT_APP_BASE_URL + path;
  }
};

// capitalize First Letter from keyword
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getCamelCaseFromKeyword = (value) => {
  let temp = value.split("_");
  let finalValue;
  for (let i = 0; i < temp.length; i++) {
    if (i === 0) {
      finalValue = capitalizeFirstLetter(temp[i]);
    } else {
      finalValue = `${finalValue} ${capitalizeFirstLetter(temp[i])}`;
    }
  }
  return finalValue;
};
const headers = {
  "Access-Control-Allow-Origin": "*",
  // "Content-Type": "application/json",
  // "Access-Control-Allow-Headers": "X-Requested-With",
  // Accept: "*/*",
};

export async function getGeoLocationAddress(query, isReverseGeocoding) {
  return new Promise(async (resolve, reject) => {
    let apiUrl = `${
      isReverseGeocoding ? MAP_ADDRES_FROM_LATLONG : MAP_ADDRESS_FROM_TEXT
    }${query}&key=${GOOGLE_MAP_API_KEY}`;
    axios
      .get(apiUrl, {
        headers: headers,
      })
      // .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((err) => {
        reject(err);
        getErrorMessage(err);
      });
  });
}

export function isValidation(value, type) {
  const emailRegex =
    /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  if (type === "email") {
    if (value === "") {
      return {
        error: "Please enter your email address",
        success: false,
      };
    } else if (!emailRegex.test(value)) {
      return {
        error: "Please enter a valid email address",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "phone") {
    if (value === "") {
      return {
        error: "Please enter phone number",
        success: false,
      };
    } else if (value?.length < 9) {
      return {
        error: "Please enter a valid phone number",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }
  if (type === "name") {
    if (value === "") {
      return {
        error: "Please enter your name",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "vila") {
    if (value === "") {
      return {
        error: "This field is required",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "building") {
    if (value === "") {
      return {
        error: "This field is required",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }

  if (type === "address") {
    if (value === "") {
      return {
        error: "This field is required",
        success: false,
      };
    } else {
      return {
        error: "",
        success: true,
      };
    }
  }
}
