import { initialTableData } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  faqLoading: false,
  faqError: "",
  faqRes: "",
  isfaqSuccessRes: false,

  isSingleFaqRes: false,
  singleFaqLoading: false,
  singleFaqError: "",
  singleFaqRes: "",
};

export default function faqReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_FAQ_METHOD:
      return {
        ...state,
        faqLoading: true,
        faqError: "",
        isfaqSuccessRes: false,
      };

    case actionTypes.GET_FAQ_SUCCESS:
      return {
        ...state,
        faqLoading: false,
        faqRes: action.payload,
        faqError: "",
        isfaqSuccessRes: true,
      };

    case actionTypes.GET_FAQ_FAIL:
      return {
        ...state,
        faqLoading: false,
        faqError: action.payload,
        isfaqSuccessRes: false,
      };

    case actionTypes.GET_FAQ_CLEAR:
      return {
        ...state,
        faqLoading: false,
        faqError: action.payload,
        faqRes: "",
        isfaqSuccessRes: false,
      };

    ////////////////////////////////////////////////////////

    case actionTypes.GET_SINGLE_FAQ_METHOD:
      return {
        ...state,
        singleFaqLoading: true,
        singleFaqError: "",
        isSingleFaqRes: false,
      };

    case actionTypes.GET_SINGLE_FAQ_SUCCESS:
      return {
        ...state,
        singleFaqLoading: false,
        singleFaqRes: action.payload,
        singleFaqError: "",
        isSingleFaqRes: true,
      };

    case actionTypes.GET_SINGLE_FAQ_FAIL:
      return {
        ...state,
        singleFaqLoading: false,
        singleFaqError: action.payload,
        isSingleFaqRes: false,
        singleFaqRes: "",
      };

    case actionTypes.GET_SINGLE_FAQ_CLEAR:
      return {
        ...state,
        singleFaqLoading: false,
        singleFaqError: action.payload,
        singleFaqRes: "",
        isSingleFaqRes: false,
      };

    default:
      return state;
  }
}
