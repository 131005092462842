import { PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  singleServiceReviewsLoading: false,
  singleServiceReviewsError: "",
  singleServiceReviewsRes: PAGINATION_INITIAL_LIST_RES,
  isSingleServiceReviewsResSuccess: false,
};

export default function singleServiceReviewsReducer(
  state = INITIAL_STATE,
  action
) {
  switch (action.type) {
    case actionTypes.GET_SINGLE_SERVICE_REVIEWS_METHOD:
      return {
        ...state,
        singleServiceReviewsLoading: true,
        singleServiceReviewsError: "",
        isSingleServiceReviewsResSuccess: false,
      };

    case actionTypes.GET_SINGLE_SERVICE_REVIEWS_SUCCESS:
      return {
        ...state,
        singleServiceReviewsLoading: false,
        singleServiceReviewsRes: action.payload,
        singleServiceReviewsError: "",
        isSingleServiceReviewsResSuccess: true,
      };

    case actionTypes.GET_SINGLE_SERVICE_REVIEWS_FAIL:
      return {
        ...state,
        singleServiceReviewsLoading: false,
        singleServiceReviewsError: action.payload,
        isSingleServiceReviewsResSuccess: false,
      };

    case actionTypes.GET_SINGLE_SERVICE_REVIEWS_CLEAR:
      return {
        ...state,
        singleServiceReviewsLoading: false,
        singleServiceReviewsError: "",
        singleServiceReviewsRes: PAGINATION_INITIAL_LIST_RES,
        isSingleServiceReviewsResSuccess: false,
      };

    default:
      return state;
  }
}
