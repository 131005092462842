import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_PROMO_CODE } from "Redux/services/apiTypes";

// ----- Get promoCodeHistory Watcher -----
export function* watchGetPromoCodeHistorySaga() {
  yield takeLatest(
    actionTypes.GET_PROMO_CODE_HISTORY_METHOD,
    getPromoCodeHistorySaga
  );
}

// ----- Saga -----
function* getPromoCodeHistorySaga(action) {
  try {
    let url = {
      type: API_PROMO_CODE + action.payload.query,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({
      type: actionTypes.GET_PROMO_CODE_HISTORY_SUCCESS,
      payload: res,
    });
  } catch (error) {
    yield put({
      type: actionTypes.GET_PROMO_CODE_HISTORY_FAIL,
      payload: error,
    });
  }
}
