import { initialTableData } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  suggestionLoading: false,
  isSuggestionResSuccess: false,
  suggestionError: "",
  suggestionRes: "",

  singleSuggestionLoading: false,
  singleSuggestionError: "",
  singleSuggestionRes: "",
  isSingleSuggestionRes: false,
};

export default function suggestionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_SUGGESTION_METHOD:
      return {
        ...state,
        suggestionLoading: true,
        suggestionError: "",
        isSuggestionResSuccess: false,
      };

    case actionTypes.GET_SUGGESTION_SUCCESS:
      return {
        ...state,
        suggestionLoading: false,
        suggestionRes: action.payload,
        suggestionError: "",
        isSuggestionResSuccess: true,
      };

    case actionTypes.GET_SUGGESTION_FAIL:
      return {
        ...state,
        suggestionLoading: false,
        suggestionError: action.payload,
        isSuggestionResSuccess: false,
      };

    case actionTypes.GET_SUGGESTION_CLEAR:
      return {
        ...state,
        suggestionLoading: false,
        suggestionError: action.payload,
        suggestionRes: "",
        isSuggestionResSuccess: false,
      };

    /////////////////////////////////////////////////////////

    case actionTypes.GET_SINGLE_SUGGESTION_METHOD:
      return {
        ...state,
        singleSuggestionLoading: true,
        suggestionError: "",
        isSingleSuggestionRes: false,
      };

    case actionTypes.GET_SINGLE_SUGGESTION_SUCCESS:
      return {
        ...state,
        singleSuggestionLoading: false,
        singleSuggestionRes: action.payload,
        singleSuggestionError: "",
        isSingleSuggestionRes: true,
      };

    case actionTypes.GET_SINGLE_SUGGESTION_FAIL:
      return {
        ...state,
        singleSuggestionLoading: false,
        singleSuggestionError: action.payload,
        isSingleSuggestionRes: false,
      };

    case actionTypes.GET_SINGLE_SUGGESTION_CLEAR:
      return {
        ...state,
        singleSuggestionLoading: false,
        singleSuggestionError: action.payload,
        singleSuggestionRes: "",
        isSingleSuggestionRes: false,
      };

    default:
      return state;
  }
}
