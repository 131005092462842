import { PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  apiLogsLoading: false,
  apiLogsError: "",
  apiLogsRes: PAGINATION_INITIAL_LIST_RES,
  isApiLogsResSuccess: false,
};

export default function apiLogsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_API_LOGS_METHOD:
      return {
        ...state,
        apiLogsLoading: true,
        apiLogsError: "",
        isApiLogsResSuccess: false,
      };

    case actionTypes.GET_API_LOGS_SUCCESS:
      return {
        ...state,
        apiLogsLoading: false,
        apiLogsRes: action.payload,
        apiLogsError: "",
        isApiLogsResSuccess: true,
      };

    case actionTypes.GET_API_LOGS_FAIL:
      return {
        ...state,
        apiLogsLoading: false,
        apiLogsError: action.payload,
        isApiLogsResSuccess: false,
      };

    case actionTypes.GET_API_LOGS_CLEAR:
      return {
        ...state,
        apiLogsLoading: false,
        apiLogsError: "",
        apiLogsRes: PAGINATION_INITIAL_LIST_RES,
        isApiLogsResSuccess: false,
      };

    default:
      return state;
  }
}
