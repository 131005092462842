import React from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { NavLink } from "react-router-dom";
import '../App.css';

const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
    margin: "0 auto",
    maxWidth: 360,
    backgroundColor: "red",
  },
  nested: {
    paddingLeft: 4,
  },
  linkColor: {
    "&:hover": {
      color: "black",
    },
    color: "black",
  },
  activeClassName: {
    "& > div": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
}));

export default function NestedList({ route }) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };



  return (
    <>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>{route.icon}</ListItemIcon>
        <ListItemText primary={route.label} />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding dense>
          {route.children.map((child, index) => (
            <NavLink
              exact={child.exact}
              key={index}
              to={child.link}
              style={{ textDecoration: "none" }}
              className={classes.linkColor}
              // activeClassName={classes.activeClassName}
              activeClassName='activeClass'
            >
              <ListItem
                button
                style={{ marginBottom: "2px", marginTop: "2px" }}
              >
                <ListItemIcon>{child.icon}</ListItemIcon>
                <ListItemText primary={child.label} />
              </ListItem>
            </NavLink>
          ))}
        </List>
      </Collapse>
    </>
  );
}
