import { initialTableData } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  sessionLoading: false,
  isSessionResSuccess: false,
  sessionError: "",
  sessionRes: "",

  slotLoading: false,
  isSlotResSuccess: false,
  slotError: "",
  slotRes: "",
};

export default function sessionReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_SESSION_METHOD:
      return {
        ...state,
        sessionLoading: true,
        sessionError: "",
        isSessionResSuccess: false,
      };

    case actionTypes.GET_SESSION_SUCCESS:
      return {
        ...state,
        sessionLoading: false,
        sessionRes: action.payload,
        sessionError: "",
        isSessionResSuccess: true,
      };

    case actionTypes.GET_SESSION_FAIL:
      return {
        ...state,
        sessionLoading: false,
        isSessionResSuccess: false,
        sessionError: action.payload,
      };

    case actionTypes.GET_SESSION_CLEAR:
      return {
        ...state,
        sessionLoading: false,
        sessionError: "",
        sessionRes: "",
        isSessionResSuccess: false,
      };

    ////////////////////////////////////////////////////////////

    case actionTypes.GET_SLOT_METHOD:
      return {
        ...state,
        slotLoading: true,
        slotError: "",
        isSlotResSuccess: false,
      };

    case actionTypes.GET_SLOT_SUCCESS:
      return {
        ...state,
        slotLoading: false,
        slotRes: action.payload,
        slotError: "",
        isSlotResSuccess: true,
      };

    case actionTypes.GET_SLOT_FAIL:
      return {
        ...state,
        slotLoading: false,
        isSlotResSuccess: false,
        slotError: action.payload,
      };

    case actionTypes.GET_SLOT_CLEAR:
      return {
        ...state,
        slotLoading: false,
        slotError: "",
        slotRes: "",
        isSlotResSuccess: false,
      };

    default:
      return state;
  }
}
