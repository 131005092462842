import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_BANNER } from "Redux/services/apiTypes";

// ----- Get Banner Watcher -----
export function* watchGetBannerSaga() {
  yield takeLatest(actionTypes.GET_BANNER_METHOD, getBannerSaga);
}
export function* watchGetSingleBannerSaga() {
  yield takeLatest(actionTypes.GET_SINGLE_BANNER_METHOD, getSingleBannerSaga);
}

// ----- Saga -----
function* getBannerSaga(action) {
  try {
    let url = {
      type: `${API_BANNER}${action.payload.query}`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_BANNER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_BANNER_FAIL, payload: error });
  }
}
function* getSingleBannerSaga(action) {
  try {
    let url = {
      type: `${API_BANNER}${action.payload}/`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SINGLE_BANNER_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SINGLE_BANNER_FAIL, payload: error });
  }
}
