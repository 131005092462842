import { PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  supplierLoading: false,
  isSupplierResSuccess: false,
  supplierError: "",
  supplierRes: PAGINATION_INITIAL_LIST_RES,

  singleSupplierLoading: false,
  isSingleSupplierResSuccess: false,
  singleSupplierError: undefined,
  singleSupplierRes: {},
};

export default function supplierReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_SUPPLIER_METHOD:
      return {
        ...state,
        supplierLoading: true,
        supplierError: "",
        isSupplierResSuccess: false,
      };

    case actionTypes.GET_SUPPLIER_SUCCESS:
      return {
        ...state,
        supplierLoading: false,
        supplierRes: action.payload,
        supplierError: "",
        isSupplierResSuccess: true,
      };

    case actionTypes.GET_SUPPLIER_FAIL:
      return {
        ...state,
        supplierLoading: false,
        isSupplierResSuccess: false,
        supplierError: action.payload,
      };

    case actionTypes.GET_SUPPLIER_CLEAR:
      return {
        ...state,
        supplierLoading: false,
        supplierError: "",
        supplierRes: PAGINATION_INITIAL_LIST_RES,
        isSupplierResSuccess: false,
      };

    ////////////////////////////////////////////////////////////

    case actionTypes.GET_SINGLE_SUPPLIER_METHOD:
      return {
        ...state,
        singleSupplierLoading: true,
        singleSupplierError: "",
        isSingleSupplierResSuccess: false,
      };

    case actionTypes.GET_SINGLE_SUPPLIER_SUCCESS:
      return {
        ...state,
        singleSupplierLoading: false,
        singleSupplierRes: action.payload,
        singleSupplierError: "",
        isSingleSupplierResSuccess: true,
      };

    case actionTypes.GET_SINGLE_SUPPLIER_FAIL:
      return {
        ...state,
        singleSupplierLoading: false,
        isSingleSupplierResSuccess: false,
        singleSupplierError: action.payload,
      };

    case actionTypes.GET_SINGLE_SUPPLIER_CLEAR:
      return {
        ...state,
        singleSupplierLoading: false,
        singleSupplierError: undefined,
        singleSupplierRes: {},
        isSingleSupplierResSuccess: false,
      };

    ////////////////////////////////////////////////////////////

    default:
      return state;
  }
}
