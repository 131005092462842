import React from "react";
import { FormControl, TextField } from "@mui/material";

const CInput = ({
  required,
  style,
  error,
  formHelperText,
  helperText,
  label,
  type,
  value,
  onChange,
  onKeyDown,
  disable,
  endAdornment,
  placeholder,
  startAdornment,
  onBlur,
}) => {
  return (
    <FormControl
      required={required ? required : false}
      fullWidth={true}
      sx={{ mt: "20px", ...style }}
      variant="standard"
      error={error ? error : false}
    >
      <TextField
        id="outlined-basic"
        variant="outlined"
        placeholder={placeholder || ""}
        disabled={disable ? disable : false}
        required={required ? required : false}
        error={error ? error : false}
        helperText={
          !!formHelperText && !error
            ? formHelperText
            : error
            ? helperText ?? formHelperText ?? "This field is required"
            : ""
        }
        label={label}
        type={type ?? "text"}
        value={value}
        onKeyDown={onKeyDown}
        onChange={(e) => {
          if (type === "number" && Number(e.target.value) < 0) {
            e.preventDefault();
          } else {
            onChange(e);
          }
        }}
        sx={{
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              border: "2px solid rgba(0,0,0,.1)",
              borderRadius: "5px",
            },
          },
          "& .MuiOutlinedInput-root:hover": {
            "& fieldset": {
              border: "2px solid rgba(0,0,0,.1)",
            },
          },
          "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
            padding: "15px 20px !important",
            fontColor: "rgba(255,255,255)",
            zIndex: 500,
          },
        }}
        InputProps={{
          endAdornment: endAdornment,
          startAdornment: startAdornment,
        }}
        onBlur={onBlur}
      />
    </FormControl>
  );
};

export default CInput;
