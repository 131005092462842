import { initialTableData, PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  userLoading: false,
  userError: "",
  userRes: PAGINATION_INITIAL_LIST_RES,
  isUserResSuccess: false,

  singleUserLoading: false,
  isSingleUserResSuccess: false,
  singleUserError: undefined,
  singleUserRes: {},
};

export default function userReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_USERS_METHOD:
      return {
        ...state,
        userLoading: true,
        userError: "",
        isUserResSuccess: false,
      };

    case actionTypes.GET_USERS_SUCCESS:
      return {
        ...state,
        userLoading: false,
        userRes: action.payload,
        userError: "",
        isUserResSuccess: true,
      };

    case actionTypes.GET_USERS_FAIL:
      return {
        ...state,
        userLoading: false,
        userError: action.payload,
        isUserResSuccess: false,
      };

    case actionTypes.GET_USERS_CLEAR:
      return {
        ...state,
        userLoading: false,
        userRes: PAGINATION_INITIAL_LIST_RES,
        userError: "",
        isUserResSuccess: false,
      };

    ///////////////////////////////////////////////

    case actionTypes.GET_SINGLE_USER_METHOD:
      return {
        ...state,
        singleUserLoading: true,
        singleUserError: "",
        isSingleUserResSuccess: false,
      };

    case actionTypes.GET_SINGLE_USER_SUCCESS:
      return {
        ...state,
        singleUserLoading: false,
        singleUserRes: action.payload,
        singleUserError: "",
        isSingleUserResSuccess: true,
      };

    case actionTypes.GET_SINGLE_USER_FAIL:
      return {
        ...state,
        singleUserLoading: false,
        isSingleUserResSuccess: false,
        singleUserError: action.payload,
      };

    case actionTypes.GET_SINGLE_USER_CLEAR:
      return {
        ...state,
        singleUserLoading: false,
        singleUserError: undefined,
        singleUserRes: {},
        isSingleUserResSuccess: false,
      };

    default:
      return state;
  }
}
