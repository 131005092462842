import { initialTableData, PAGINATION_INITIAL_LIST_RES } from "utils/constant";
import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  categoryLoading: false,
  categoryError: "",
  categoryRes: PAGINATION_INITIAL_LIST_RES,
  isCategoryResSuccess: false,

  subCategoryLoading: false,
  subCategoryError: "",
  subCategoryRes: PAGINATION_INITIAL_LIST_RES,
  isSubCategoryResSuccess: false,

  thirdCategoryLoading: false,
  thirdCategoryError: "",
  thirdCategoryRes: PAGINATION_INITIAL_LIST_RES,
  isThirdCategoryResSuccess: false,
};

export default function categoryReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_CATEGORY_METHOD:
      return {
        ...state,
        categoryLoading: true,
        categoryError: "",
        isCategoryResSuccess: false,
      };

    case actionTypes.GET_CATEGORY_SUCCESS:
      return {
        ...state,
        categoryLoading: false,
        categoryRes: action.payload,
        categoryError: "",
        isCategoryResSuccess: true,
      };

    case actionTypes.GET_CATEGORY_FAIL:
      return {
        ...state,
        categoryLoading: false,
        categoryError: action.payload,
        isCategoryResSuccess: false,
      };

    case actionTypes.GET_CATEGORY_CLEAR:
      return {
        ...state,
        categoryLoading: false,
        categoryError: "",
        categoryRes: PAGINATION_INITIAL_LIST_RES,
        isCategoryResSuccess: false,
      };

    ///////////////////////////////////////////////////////

    case actionTypes.GET_SUB_CATEGORY_METHOD:
      return {
        ...state,
        subCategoryLoading: true,
        subCategoryError: "",
        isSubCategoryResSuccess: false,
      };

    case actionTypes.GET_SUB_CATEGORY_SUCCESS:
      return {
        ...state,
        subCategoryLoading: false,
        subCategoryRes: action.payload,
        subCategoryError: "",
        isSubCategoryResSuccess: true,
      };

    case actionTypes.GET_SUB_CATEGORY_FAIL:
      return {
        ...state,
        subCategoryLoading: false,
        subCategoryError: action.payload,
        isSubCategoryResSuccess: false,
      };

    case actionTypes.GET_SUB_CATEGORY_CLEAR:
      return {
        ...state,
        subCategoryLoading: false,
        subCategoryError: "",
        subCategoryRes: PAGINATION_INITIAL_LIST_RES,
        isSubCategoryResSuccess: false,
      };

    ///////////////////////////////////////////////////////

    case actionTypes.GET_THIRD_CATEGORY_METHOD:
      return {
        ...state,
        thirdCategoryLoading: true,
        thirdCategoryError: "",
        isThirdCategoryResSuccess: false,
      };

    case actionTypes.GET_THIRD_CATEGORY_SUCCESS:
      return {
        ...state,
        thirdCategoryLoading: false,
        thirdCategoryRes: action.payload,
        thirdCategoryError: "",
        isThirdCategoryResSuccess: true,
      };

    case actionTypes.GET_THIRD_CATEGORY_FAIL:
      return {
        ...state,
        thirdCategoryLoading: false,
        thirdCategoryError: action.payload,
        isThirdCategoryResSuccess: false,
      };

    case actionTypes.GET_THIRD_CATEGORY_CLEAR:
      return {
        ...state,
        thirdCategoryLoading: false,
        thirdCategoryError: "",
        thirdCategoryRes: PAGINATION_INITIAL_LIST_RES,
        isThirdCategoryResSuccess: false,
      };

    default:
      return state;
  }
}
