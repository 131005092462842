import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { SERVICE_REVIEWS } from "Redux/services/apiTypes";

// ----- Get userReviews Watcher -----
export function* watchGetUserReviewsSaga() {
  yield takeLatest(actionTypes.GET_USER_REVIEWS_METHOD, getUserReviewsSaga);
}

// ----- Saga -----
function* getUserReviewsSaga(action) {
  try {
    let url = {
      type: `${SERVICE_REVIEWS}${action.payload.query}`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_USER_REVIEWS_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_USER_REVIEWS_FAIL, payload: error });
  }
}
