import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  withdrawalListLoading: false,
  withdrawalListError: "",
  withdrawalListRes: "",
};

export default function withdrawalListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_WITHDRAWAL_LIST_METHOD:
      return {
        ...state,
        withdrawalListLoading: true,
        withdrawalListError: "",
      };

    case actionTypes.GET_WITHDRAWAL_LIST_SUCCESS:
      return {
        ...state,
        withdrawalListLoading: false,
        withdrawalListRes: action.payload,
        withdrawalListError: "",
      };

    case actionTypes.GET_WITHDRAWAL_LIST_FAIL:
      return {
        ...state,
        withdrawalListLoading: false,
        withdrawalListError: action.payload,
      };

    case actionTypes.GET_WITHDRAWAL_LIST_CLEAR:
      return {
        ...state,
        withdrawalListLoading: false,
        withdrawalListError: action.payload,
        withdrawalListRes: "",
      };
    default:
      return state;
  }
}
