import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_FAQ } from "Redux/services/apiTypes";

// ----- Get Faq Watcher -----
export function* watchGetFaqSaga() {
  yield takeLatest(actionTypes.GET_FAQ_METHOD, getFaqSaga);
}
export function* watchGetSingleFaqSaga() {
  yield takeLatest(actionTypes.GET_SINGLE_FAQ_METHOD, getSingleFaqSaga);
}

// ----- Saga -----
function* getFaqSaga(action) {
  try {
    let url = {
      type: API_FAQ + action.payload.query,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_FAQ_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_FAQ_FAIL, payload: error });
  }
}

function* getSingleFaqSaga(action) {
  try {
    let url = {
      type: `${API_FAQ}${action.payload}/`,
      payload: action.payload,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_SINGLE_FAQ_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_SINGLE_FAQ_FAIL, payload: error });
  }
}
