import { Suspense } from "react";
import "./App.css";

// mui
import { Grid } from "@mui/material";

// navigation
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ScreenRoutes from "routes/ScreenRoutes";

// common
import Loading from "common/loading";

// toaster
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

// component
import Layout from "layout";
import Login from "components/Login";
import ProtectedRoutes from "routes/ProtectedRoutes";

// utils
import { roles } from "utils/constant";

function App() {
  const CustomLoading = () => {
    return (
      <Grid
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "rgba(0,0,0,.15)",
        }}
      >
        <Loading />
      </Grid>
    );
  };
  return (
    <Suspense fallback={<CustomLoading />}>
      <Router>
        <Routes>
          <Route path="/" element={<Navigate to="/admin/login" replace />} />

          <Route path="/admin/login" element={<Login />} />

          <Route
            path="/admin/"
            element={
              <ProtectedRoutes
                role={[roles.is_staff, roles.is_service_provider]}
              />
            }
          >
            <Route path="/admin/" name="Home" element={<Layout />}>
              {ScreenRoutes.map((route, idx) => {
                return (
                  <Route
                    key={`Layout_${idx}`}
                    path={route.path}
                    name={route.name}
                    element={route.element}
                  />
                );
              })}
            </Route>
          </Route>
        </Routes>
      </Router>
      <ToastContainer />
      {/* <NotificationAll /> */}
    </Suspense>
  );
}

export default App;
