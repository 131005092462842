import { actionTypes } from "../services/actionTypes";

const INITIAL_STATE = {
  paybackListLoading: false,
  paybackListError: "",
  paybackListRes: "",
};

export default function paybackListReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case actionTypes.GET_PAYBACK_LIST_METHOD:
      return {
        ...state,
        paybackListLoading: true,
        paybackListError: "",
      };

    case actionTypes.GET_PAYBACK_LIST_SUCCESS:
      return {
        ...state,
        paybackListLoading: false,
        paybackListRes: action.payload,
        paybackListError: "",
      };

    case actionTypes.GET_PAYBACK_LIST_FAIL:
      return {
        ...state,
        paybackListLoading: false,
        paybackListError: action.payload,
      };

    case actionTypes.GET_PAYBACK_LIST_CLEAR:
      return {
        ...state,
        paybackListLoading: false,
        paybackListError: action.payload,
        paybackListRes: "",
      };
    default:
      return state;
  }
}
