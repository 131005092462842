import { put, takeLatest } from "redux-saga/effects";
import { actionTypes } from "Redux/services/actionTypes";
import apiCall from "Redux/services/apiCall";
import { API_DASHBOARD } from "Redux/services/apiTypes";

export function* watchGetDashboardDataWatcher() {
  yield takeLatest(
    actionTypes.GET_DASHBOARD_DATA_METHOD,
    getDashboardDataWorker
  );
}

function* getDashboardDataWorker(action) {
  try {
    let url = {
      type: API_DASHBOARD + action.payload.query,
      apiType: "GET",
    };
    const res = yield apiCall(url);
    yield put({ type: actionTypes.GET_DASHBOARD_DATA_SUCCESS, payload: res });
  } catch (error) {
    yield put({ type: actionTypes.GET_DASHBOARD_DATA_FAIL, payload: error });
  }
}
